import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getLineItems: 'cart/getLineItems',
    }),
    cartLineItems() {
      return this.lineItems || this.getLineItems;
    },
    comboTotal() {
      return this.cartLineItems &&
        this.cartLineItems.filter(lineItem => lineItem.type === 35).length > 0
        ? this.cartLineItems
            .filter(lineItem => lineItem.type === 35)
            .map(lineItem => lineItem.extended)
            .reduce((prev, next) => prev + next)
        : 0;
    },
    externalCouponsDiscountTotal() {
      return this.cartLineItems &&
        this.cartLineItems.filter(lineItem => lineItem.type === 31).length > 0
        ? this.cartLineItems
            .filter(lineItem => lineItem.type === 31)
            .map(lineItem => lineItem.extended)
            .reduce((prev, next) => prev + next)
        : 0;
    },
    discountPriceTotal() {
      return this.cartLineItems &&
        this.cartLineItems.filter(lineItem => lineItem.type === 25).length > 0
        ? this.cartLineItems
            .filter(lineItem => lineItem.type === 25)
            .map(lineItem => lineItem.extended)
            .reduce((prev, next) => prev + next)
        : 0;
    },
    totalSavings() {
      return this.comboTotal + this.externalCouponsDiscountTotal + this.discountPriceTotal;
    },
  },
};
