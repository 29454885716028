import { store } from '@/store';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

/**
 * Reject gift card access for employees and disabled E-Gift card configuration
 * And allow for other cases
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function giftCardAccess(to, from, next) {
  const isEmployee = store.getters['user/isEmployee'];
  const isCustomer = store.getters['user/isCustomer'];
  const allowEGiftCards = store.getters.getConfiguration.allowEGiftCards;

  /* Check if logged in user is only employee (not associated with any customer),
   * then restrict access, else redirect
   */
  if (isEmployee && !isCustomer) {
    next({ name: 'EmployeeCustomerSelect' });
    ToastService.open(WCSimpleToast, {
      props: {
        variant: 'warning',
        title: 'Authentication',
        message: 'Associate as customer to access the page',
      },
      timeout: 7000,
    });
  } else if (!allowEGiftCards) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
