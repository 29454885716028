<template>
  <div class="wc-layout wc-layout--mobile-default">
    <WCMobileHeader />
    <div class="wc-layout__body container-fluid px-0">
      <router-view />
    </div>
  </div>
</template>

<script>
import WCMobileHeader from '@/components/WCMobileHeader/WCMobileHeader.vue';

export default {
  name: 'MobileScanView',
  components: {
    WCMobileHeader,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-headerless {
  .wc-layout__body {
    padding-bottom: $pad-3 * 3.7;
  }
}
</style>
