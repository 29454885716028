<template>
  <div class="small" :class="isValid ? 'text-success' : 'text-danger'">
    <span class="d-block">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'WCValidationMessage',
  props: {
    isValid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
