import { mapActions, mapGetters } from 'vuex';
import TrackableEventConstants from '@/constants/TrackableEventConstants';
import AnalyticsService from '@/services/analytics.service';

export default {
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
    }),
  },
  methods: {
    ...mapActions({
      addListItem: 'lists/addListItem',
      addListItems: 'lists/addListItems',
    }),
    async addItemToList(payload) {
      const { item } = payload;
      AnalyticsService.track(TrackableEventConstants.ItemAddedToList, {
        items: [item],
        customerId: this.getUser?.sub,
      });
      return this.addListItem(payload);
    },
    async addItemsToList(payload) {
      AnalyticsService.track(TrackableEventConstants.ItemAddedToList, {
        items: payload.items,
        customerId: this.getUser?.sub,
      });
      return this.addListItems(payload);
    },
  },
};
