<template>
  <div class="wc-layout wc-layout--basic">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Basic',
};
</script>

<style scoped lang="scss">
.wc-layout--basic {
  height: 100%;
}
</style>
