<template>
  <div>
    <PlatformRenderer>
      <template v-slot:onMobile>
        <div>
          <WCMobileSearchHeader
            @search="search"
            @fetchSuggestions="fetchSuggestions"
            :suggestions="suggestions"
            :itemGroups="itemGroups"
          />
        </div>
      </template>
      <template v-slot:onDesktop>
        <!-- TODO: Implement desktop view in case they get here-->
      </template>
    </PlatformRenderer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCMobileSearchHeader from '../WCMobileSearchHeader/WCMobileSearchHeader.vue';

export default {
  components: { WCMobileSearchHeader, PlatformRenderer },
  computed: {
    ...mapGetters({
      suggestions: 'browse/getSuggestions',
    }),
    getMenu() {
      return this.$configuration.mainMenu;
    },
    itemGroups() {
      return this.$configuration.mainMenu.children;
    },
  },
  methods: {
    ...mapActions({ fetchSuggestions: 'browse/fetchSuggestions' }),
    search({ term, department }) {
      this.$router.push({
        name: 'MobileBrowse',
        query: { q: term, ...(department ? { Dept: [department] } : undefined) },
      });
    },
  },
};
</script>
