import { store } from '@/store';
import rejectEmployeeAccess from '../reject-employee-access/reject-employee-access';

/**
 * Reject coupon pages if disabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function couponAccess(to, from, next) {
  const allowCouponClipping = store.getters.getConfiguration.allowCouponClipping;
  const isEmployee = store.getters['user/isEmployee'];
  const isCustomer = store.getters['user/isCustomer'];

  if (isEmployee && !isCustomer) {
    rejectEmployeeAccess(to, from, next);
  } else if (!allowCouponClipping) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
