<template>
  <div v-if="cart && cart.giftCardItems && cart.giftCardItems.length">
    <WCCartDrawerGiftCardItem
      v-for="giftCardItem in cart.giftCardItems"
      :key="giftCardItem.id"
      :giftCard="giftCardItem"
    />
  </div>
  <div v-else-if="giftCardItems && giftCardItems.length && !cart">
    <WCCartDrawerGiftCardItem
      v-for="giftCardItem in giftCardItems"
      :key="giftCardItem.id"
      :giftCard="giftCardItem"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import WCCartDrawerGiftCardItem from '../WCCartDrawerGiftCardItem/WCCartDrawerGiftCardItem.vue';

export default {
  name: 'WCCartDrawerGiftCard',
  components: {
    WCCartDrawerGiftCardItem,
  },
  props: {
    cart: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      giftCardItems: 'cart/getGiftCardItems',
    }),
  },
};
</script>

<style scoped>
.horizontal-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
