<template>
  <WCModal position="center" size="large">
    <WCModalHeader>{{ $t('editExpiredHeader') }}</WCModalHeader>
    <WCModalBody>
      <p>{{ $t('editExpiredMessage') }}</p>
    </WCModalBody>

    <WCModalFooter>
      <div class="justify-content-center d-flex flex-grow-1">
        <button class="btn btn-primary" @click="dismiss(MODAL_EVENTS.DISMISS)">
          {{ $t('ok') }}
        </button>
      </div>
    </WCModalFooter>
  </WCModal>
</template>

<script>
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';

export default {
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  mixins: [ModalMixin],
};
</script>
