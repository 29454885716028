export default {
  computed: {
    /**
     * @returns The URL for the E-Gift Card builder
     */
    giftCardUrl() {
      const giftCardOrderType = this.$configuration.orderTypes?.find(
        orderType => orderType.giftCard,
      );

      return giftCardOrderType ? `/orders/${giftCardOrderType.id}` : `/gc`;
    },
  },
};
