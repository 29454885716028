import axios from 'axios';

class ConfigurationService {
  configuration;

  async load() {
    if (this.configuration) {
      return this.configuration;
    }

    const response = await axios.get('api/configuration');
    this.configuration = response.data;
    return this.configuration;
  }
}

export default new ConfigurationService();
