<template>
  <div class="wc-menu">
    <slot name="listItems"></slot>
    <slot name="extraElements"></slot>
  </div>
</template>

<script>
export default {
  name: 'WCMobileContextMenu',
};
</script>

<style lang="scss" scoped>
.wc-menu {
  position: absolute;
  bottom: 0;
  width: 100%;

  li {
    cursor: pointer;
  }
}
</style>
