import { PAYMENT_METHOD_TYPES } from '@/constants/AppConstants';

/**
 * Method to check if token is expired or not
 * @param {String} expiration
 * @returns Boolean
 */
function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
export const isTokenExpired = expiration => {
  const expString = `${expiration.substring(0, 2)}/20${expiration.substring(2, 4)}`;
  const expMonYear = expString.split('/');
  const expDays = getDaysInMonth(expiration.substring(0, 2), expiration.substring(2, 4));
  const expDateString = new Date(expMonYear[1], +expMonYear[0] - 1, expDays);
  const today = new Date();
  if (expDateString > today) {
    return false;
  }
  return true;
};

/**
 * Get only those payment methods that are credit / debit cards from a list.
 *
 * Returns null if none.
 * @param {Object[]} paymentMethods List of payment methods to filter
 * @returns {Object[]}
 */
export const getOnlyCardPaymentMethods = paymentMethods => {
  const cardMethods = paymentMethods
    ? paymentMethods.filter(m => m.type === PAYMENT_METHOD_TYPES.TOKENIZATION)
    : null;
  return cardMethods && cardMethods.length > 0 ? cardMethods : null;
};
