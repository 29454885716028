import ItemSummary from '@/models/entities/items/item-summary';
import TypedDefaultPk from '@/models/entities/typed-default-pk';
import BigNumber from 'bignumber.js';

export function isAdditionalScanCode(item: ItemSummary): boolean {
  try {
    const id = new TypedDefaultPk(item.id);
    return id.isAdditionalScanCode();
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function isSoldByWeight(item: ItemSummary): boolean {
  if (isAdditionalScanCode(item)) {
    return false;
  }
  return Boolean(item.weightProfile);
}

export function isSoldByAverageWeight(item: ItemSummary): boolean {
  if (isAdditionalScanCode(item)) {
    return false;
  }
  return item.averageWeight >= 0;
}

export function isSoldByRandomWeight(item: ItemSummary): boolean {
  return isSoldByWeight(item) && !isSoldByAverageWeight(item);
}

export function hasWeightAbbreviation(item: ItemSummary): boolean {
  return isSoldByWeight(item) && Boolean(item.weightProfile.abbrv);
}

export function isPriceDivided(item: ItemSummary): boolean {
  return Boolean(item.actualPriceDivider) && item.actualPriceDivider > 1;
}

export function unitPrice(item: ItemSummary): BigNumber {
  const actualPriceDivider = isPriceDivided(item) ? item.actualPriceDivider : 1;
  return new BigNumber(item.actualPrice).dividedBy(new BigNumber(actualPriceDivider));
}

export function suggestedPrice(item: ItemSummary): BigNumber {
  return new BigNumber(item.suggestedPrice);
}

export function actualPrice(item: ItemSummary): BigNumber {
  return new BigNumber(item.actualPrice);
}

export function hasActualPrice(item: ItemSummary): boolean {
  return actualPrice(item).isPositive();
}

export function averageWeight(item: ItemSummary): BigNumber {
  return new BigNumber(item.averageWeight);
}

export function discountAmount(item: ItemSummary): BigNumber {
  return suggestedPrice(item).minus(unitPrice(item));
}

export function isDiscounted(item: ItemSummary): boolean {
  return discountAmount(item).isGreaterThan(new BigNumber(0));
}

export function isDiscountedInCart(item: ItemSummary): boolean {
  return Boolean(item.autoDiscount || item.buyDown);
}
