<template>
  <div class="wc-order-type-card card border-0">
    <router-link :to="`${routeSrc}`">
      <WCImage
        :imageSrc="imageSrc"
        :imageAlt="orderType.name"
        class="wc-order-type-image clearfix card-img-top"
      />
      <div>
        <h3 class="font-weight-bold text-center card-body">{{ orderType.name }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
import WCImage from '@/components/WCImage/WCImage.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';

export default {
  name: 'WCOrderTypeCard',
  components: { WCImage },
  props: {
    orderType: {
      type: Object,
      required: true,
    },
  },
  mixins: [OrderTypesMixin],
  computed: {
    routeSrc() {
      return this.orderTypeHomeForOrderType(this.orderType);
    },
    imageSrc() {
      return `${this.$configuration.cdn}/api/img/${this.orderType.image}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-order-type-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-grow: 1;
  height: 100%;
  background-color: transparent;
}

.wc-order-type-image {
  height: 8.75rem;
  width: 17.25rem;

  object-fit: fill;
  max-width: 100%;
  margin: auto;

  border-radius: 0.75em;
}

a {
  color: var(--dark, $dark);
}
</style>
