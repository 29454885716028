<template>
  <button
    class="mobile-btn btn btn-primary btn-block wc-coupon-btn"
    type="button"
    :id="'coupon_' + couponId"
    :class="[isClippedOrRedeemed ? 'wc-coupon-btn__clipped' : 'wc-coupon-btn__available']"
    @click="handleClick"
    :aria-pressed="isClipped || isRedeemed ? 'true' : 'false'"
    :disabled="isDisabled || isClippedOrRedeemed"
  >
    <div class="wc-coupon-btn__icon">
      <font-awesome-icon v-if="!isClipped && !isRedeemed" aria-hidden="true" icon="scissors" />
      <font-awesome-icon v-else aria-hidden="true" icon="circle-check" />
    </div>

    {{ label }}
  </button>
</template>

<script>
import { COUPON_STATUS_FILTERS } from '@/constants/CouponConstants';

export default {
  name: 'WCCouponClipButton',
  props: {
    couponId: {
      required: true,
      type: String,
    },
    availableLabel: {
      required: true,
      type: String,
    },
    clippedLabel: {
      required: true,
      type: String,
    },
    redeemedLabel: {
      required: true,
      type: String,
    },
    couponFilter: {
      type: String,
    },
    isClipped: {
      type: Boolean,
      default: false,
    },
    isRedeemed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleClip: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    getClipState() {
      return this.isClipped;
    },
    label: {
      get() {
        if (this.couponFilter === COUPON_STATUS_FILTERS.CLIPPED) {
          return this.clippedLabel;
        }
        if (this.couponFilter === COUPON_STATUS_FILTERS.REDEEMED) {
          return this.redeemedLabel;
        }
        if (this.isClipped) {
          return this.clippedLabel;
        }
        if (this.isRedeemed) {
          return this.redeemedLabel;
        }
        return this.availableLabel;
      },
      set(newLabel) {
        return newLabel;
      },
    },
    isDisabled() {
      return this.disabled;
    },
    isClippedOrRedeemed() {
      return (
        this.isClipped ||
        this.isRedeemed ||
        this.couponFilter === COUPON_STATUS_FILTERS.CLIPPED ||
        this.couponFilter === COUPON_STATUS_FILTERS.REDEEMED
      );
    },
  },
  methods: {
    getClass() {
      return this.isClipped ? 'wc-coupon-btn__clipped' : 'wc-coupon-btn__available';
    },
    handleClick() {
      if (!this.isClipped) {
        this.handleClip(this.couponId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  font-size: $font-size-lg;
  color: var(--primary-contrast, get-color-contrast('primary'));
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
}

.wc-coupon-btn__available {
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
}

.wc-coupon-btn__clipped {
  color: var(--primary, $primary);
  background-color: var(--primary-contrast, get-color-contrast('primary'));
}

.wc-coupon-btn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  color: var(--primary, $primary);
  background-color: var(--primary-contrast, get-color-contrast('primary'));
}

.wc-coupon-btn__icon {
  margin-right: $margin-2;
}
</style>
