import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import logoutUser from '@/utils/services/logoutUser';
import ScanAndGoMixin from '@/modules/user/mixins/ScanAndGoMixin';

export default {
  mixins: [ScanAndGoMixin],
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
      isGuest: 'user/isGuest',
      user: 'user/getUser',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    name() {
      let name = 'Guest';
      if (this.isEmployee && this.isCustomer) {
        name = `${this.user.emn} for ${this.user.dispShort}`;
      }
      if (this.isCustomer && this.user.dispShort) {
        name = this.user.dispShort;
      }

      if (this.isEmployee && this.user.emn) {
        name = this.user.emn;
      }

      return name;
    },
  },
  methods: {
    ...mapActions({
      reloadCart: 'cart/reload',
      fetch: 'lists/fetch',
    }),
    async logout() {
      const isScanAndGoMode = this.isCustomerModeScan;
      try {
        if (isScanAndGoMode) await this.exitScanAndGoMode();
        await logoutUser();
      } catch (error) {
        console.error(error);
      } finally {
        // Ignore Vue 2 router errors. TODO: Handle in Vue 3
        this.$router.push({ name: 'Home' }).catch(() => {});
      }
    },
    async setUserHomeStore(home) {
      try {
        await axios.post('/api/auth/home', { home });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
