import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject wallet access if it's not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function walletAccess(to, from, next) {
  const allowWallet = store.getters.getConfiguration.walletEnabled;
  const isCustomer = store.getters['user/isCustomer'];

  /* If wallet isn't enabled, redirect to not found page
   */
  if (!isCustomer && allowWallet) {
    requireCustomerLogin(to, from, next);
  } else if (!allowWallet) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
