import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      MINUTE_IN_MILISECONDS: 60000 as number,
    };
  },
  computed: {
    ...mapGetters({
      getEbtSnapBalance: 'cart/getEbtSnapBalance',
      getEbtCashBalance: 'cart/getEbtCashBalance',
      getEbtBalanceTimeStamp: 'cart/getEbtBalanceTimeStamp',
    }),
    getEbtSnapCachedBalance: {
      cache: false,
      get(): number {
        if (this.isEbtCacheValid) {
          return this.getEbtSnapBalance;
        }
        return null;
      },
    },
    getEbtCashCachedBalance: {
      cache: false,
      get(): number {
        if (this.isEbtCacheValid) {
          return this.getEbtCashBalance;
        }
        return null;
      },
    },
    isEbtCacheValid: {
      cache: false,
      get(): boolean {
        const ebtTimeout = this.$configuration.ebtBalanceTimeout;
        const ebtBalanceTimeStamp = this.getEbtBalanceTimeStamp;
        return (
          ebtBalanceTimeStamp &&
          ebtTimeout &&
          (Date.now() - ebtBalanceTimeStamp) / this.MINUTE_IN_MILISECONDS <= ebtTimeout
        );
      },
    },
  },
  methods: {
    ...mapActions({
      setEbtSnapBalance: 'cart/setEbtSnapBalance',
      setEbtCashBalance: 'cart/setEbtCashBalance',
      setEbtBalanceTimestamp: 'cart/setEbtBalanceTimestamp',
    }),
    invalidateEbtBalanceCache(): void {
      this.setEbtSnapBalance(null);
      this.setEbtCashBalance(null);
      this.setEbtBalanceTimestamp(null);
    },
  },
};
