import { DEFAULT_PRODUCT_IMG } from '../constants/AppConstants';

export default {
  methods: {
    /**
     * Method to get product image i18n alt text
     * @param {String} productName - product name
     * @param {String} brandName - product brand name
     * @returns {String} - product image i18n alt text
     */
    productImgAltText(productName, brandName) {
      return this.$t('productImgAltText', { productName, brandName });
    },

    /**
     * Method to get product image by image id
     * @param {String} productImageId  - Image id
     * @param {Number} height - height of the
     * @returns {String} - product image URL
     */
    getProductImage(productImageId, height = null) {
      const sizeQuery = height ? `?h=${height}` : '';
      return `${this.$configuration.cdn}/api/img/${productImageId}${sizeQuery}`;
    },

    /**
     * Method to get default product image
     * @returns {String} - default product image URL
     */
    getDefaultProductImg() {
      return DEFAULT_PRODUCT_IMG;
    },

    /**
     * Method to get product image not available alt text
     * @param {String} name - product name
     * @returns {String} - product image not available alt text
     */
    noProductImageAltText(name) {
      return this.$t('noProductImageAvailable', { name });
    },
  },
};
