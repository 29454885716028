import { capitalizeFirstLetter } from '@/utils/formatters';

export default {
  filters: {
    /**
     * Filter to make first letter only capitalized
     * @param {String} value
     * @returns formattedString
     */
    capitalizeFirstLetter,
  },
};
