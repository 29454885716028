<template>
  <div class="h-auto">
    <WCModal @dismiss="dismiss(MODAL_EVENTS.CANCEL)" position="center">
      <WCModalHeader class="justify-content-start">
        <font-awesome-icon icon="cart-shopping" size="lg" class="mx-2" />
        <span class="text text-primary">{{ $t('clearYourCart') }}</span>
      </WCModalHeader>
      <WCModalBody>
        <div>
          {{ $t('cartNotEligible') }}
          <span class="font-weight-bold">{{ $t('saveBeforeClearCart') }}</span>
        </div>
      </WCModalBody>
      <WCModalFooter class="justify-content-center">
        <WCSpinner v-if="isSyncing" />
        <div v-else class="d-flex">
          <button
            @click="dismiss(MODAL_EVENTS.CANCEL)"
            class="btn btn-outline-primary btn-block m-2"
          >
            {{ $t('noClearMyCart') }}
          </button>
          <button @click="close(MODAL_EVENTS.CONFIRM)" class="btn btn-primary btn-block m-2">
            {{ $t('yesSaveItemsToList') }}
          </button>
        </div>
      </WCModalFooter>
    </WCModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';

export default {
  components: {
    WCModal,
    WCModalBody,
    WCModalHeader,
    WCModalFooter,
    WCSpinner,
  },
  mixins: [ModalMixin, ItemAddToListMixin, CartItemControls],
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
    }),
  },
};
</script>
