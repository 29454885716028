<template>
  <div class="d-flex wc-select-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WCSelectBoxGroup',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-select-group {
  margin-right: $margin-n2;
  margin-left: $margin-n2;
}
</style>
