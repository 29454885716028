import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject item search access if it's not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function searchAccess(to, from, next) {
  const allowItemSearch = store.getters.getConfiguration.itemSearchEnabled;
  const isCustomer = store.getters['user/isCustomer'];
  const isEmployee = store.getters['user/isEmployee'];

  /*
   * If employee is logged in, make them associate with a customer
   * If item search isn't enabled, redirect to not found page
   */
  if (isEmployee && !isCustomer) {
    requireCustomerLogin(to, from, next);
  } else if (!allowItemSearch) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
