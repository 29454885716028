<template>
  <WCModal position="center">
    <WCModalHeader class="d-flex align-items-center">
      <h3 class="mb-0">
        {{ $t('specialOrderItem') }}
      </h3>
      <button
        class="btn btn-link underline-link-from-center"
        @click="
          dismiss('Dismissed');
          sync();
        "
      >
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <div class="">
        <WCFormGroup>
          <WCLabel>{{ $t('pleaseEnterDescription') }}</WCLabel>
          <WCTextArea
            class="form-control mt-1 border-dark"
            :lineItem="lineItem"
            v-model="lineItem.instructions"
          />
          <p v-if="lineItem.instructions.length > 256" class="text-danger">
            {{ $t('descriptionTooLong') }}
          </p>
          <p v-if="lineItem.instructions.length === 0" class="text-danger">
            {{ $t('descriptionTooShort') }}
          </p>
        </WCFormGroup>
      </div>
    </WCModalBody>
    <WCModalFooter class="justify-content-between">
      <button
        class="btn btn-primary justify-content-between align-items-center w-25"
        :lineItem="lineItem"
        @click="editSpecialOrderItem(lineItem)"
        :disabled="lineItem.instructions.length === 0 || lineItem.instructions.length > 256"
      >
        {{ $t('edit') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>
<script>
import { mapActions } from 'vuex';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCTextArea from '@/modules/forms/components/WCTextArea/WCTextArea.vue';
import WCLabel from '@/modules/forms/components/WCLabel/WCLabel.vue';
import WCFormGroup from '@/modules/forms/components/WCFormGroup/WCFormGroup.vue';

export default {
  name: 'WCEditSpecialOrderItemModal',
  mixins: [ModalMixin],
  components: {
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCModalFooter,
    WCTextArea,
    WCLabel,
    WCFormGroup,
  },
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      instructions: this.lineItem.instructions || '',
    };
  },
  methods: {
    ...mapActions({
      updateSpecialOrderItem: 'cart/updateSpecialOrderItem',
      sync: 'cart/sync',
    }),
    editSpecialOrderItem(lineItem) {
      try {
        this.updateSpecialOrderItem(lineItem);
        this.close();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.w-60 {
  width: 60% !important;
}
</style>
