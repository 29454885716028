<template>
  <div class="wc-card-group" :class="{ 'border-radius': hasBorderRadius }">
    <slot
      v-bind:collapseCardStatusList="collapseCardStatusList"
      v-bind:updateCollapseCard="updateCollapseCard"
    ></slot>
  </div>
</template>

<script>
export default {
  name: 'WCCollapseCardGroup',
  props: {
    count: {
      // Collapse Card Count
      type: Number,
      default: 0,
    },
    initialActiveIdx: {
      // Initial active collapse Card index
      type: Number,
      default: 0,
    },
    oneActiveCard: {
      // Is only one active card from the group is allowed to be active at a time
      type: Boolean,
      default: false,
    },
    hasBorderRadius: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const collapseCardStatusList = [];
    // Construct the collapse card status array
    for (let i = 0; i < this.count; i += 1) {
      collapseCardStatusList.push(this.initialActiveIdx !== i);
    }

    return {
      collapseCardStatusList,
    };
  },
  methods: {
    /**
     * Method to open active collapse card and close other sibling collapse card within the section.
     * @param {Number} selectedIndex - To indicate selected collapsed card index
     * @param {Boolean} isCollapsed - To indicate if the selected card collapsed
     */
    updateCollapseCard(selectedIndex, isCollapsed) {
      if (this.oneActiveCard) {
        this.collapseCardStatusList = this.collapseCardStatusList.map((isCardCollapsed, index) => {
          let isCollapsedStatus = isCardCollapsed;
          if (selectedIndex === index) {
            isCollapsedStatus = isCollapsed;
          } else if (isCollapsed === false) {
            isCollapsedStatus = true;
          }
          return isCollapsedStatus;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wc-card-group .wc-card {
  border-radius: 0;

  &:not(:last-child) {
    border-bottom: 0; /* So that borders between cards are only one line and not 2 */
  }
}

.wc-card-group.border-radius .wc-card {
  &:first-child {
    border-top-left-radius: 0.37rem;
    border-top-right-radius: 0.37rem;
  }
  &:last-child {
    border-bottom-left-radius: 0.37rem;
    border-bottom-right-radius: 0.37rem;
  }
}
</style>
