<template>
  <div class="wc-layout wc-layout--desktop-default d-flex flex-column">
    <WCHeader />
    <WCWebAds v-if="webAds && webAds.length" :webAds="webAds" :key="this.webAdKey" />
    <section class="wc-layout__body container py-2 flex-grow-1">
      <WCAlertRoot />
      <slot>
        <router-view />
      </slot>
    </section>
    <WCFooter class="flex-shrink-0 mt-5" />
  </div>
</template>

<script>
import WCAlertRoot from '@/modules/alerts/components/WCAlertRoot/WCAlertRoot.vue';
import IneligibleCartCheckMixin from '@/mixins/IneligibleCartCheckMixin';
import { mapGetters } from 'vuex';
import WCWebAds from '@/components/WCWebAds/WCWebAds.vue';
import WCFooter from '../components/WCFooter/WCFooter.vue';
import WCHeader from '../components/WCHeader/WCHeader.vue';

export default {
  name: 'DesktopDefault',
  components: { WCHeader, WCFooter, WCWebAds, WCAlertRoot },
  mixins: [IneligibleCartCheckMixin],
  computed: {
    ...mapGetters({ webAds: 'browse/getWebAds' }),
    webAdKey() {
      return JSON.stringify(this.webAds); // Needed to force WebAds to reload if new images are required.
    },
  },
};
</script>
<style lang="scss" scoped>
.wc-layout--desktop-default {
  min-height: 100%;
}
</style>
