export function getLocationEmail(store) {
  if (!store.emails || !store.emails.length) {
    return null;
  }
  let email = store.emails.find(e => e.webcart);
  if (!email) email = store.emails.find(e => e.location);
  return {
    ...email,
    toString() {
      return this.email;
    },
  };
}

export function getLocationPhone(store) {
  if (!store.phones || !store.phones.length) {
    return null;
  }

  let phone = store.phones.find(p => p.webcartPhone);
  if (!phone) phone = store.phones.find(p => p.locationPhone);
  return {
    ...phone,
    toString() {
      return this.phone;
    },
  };
}

function findAddress(store) {
  let address = store.addresses.find(a => a.webcart);
  if (!address) {
    address = store.addresses.find(a => a.location);
  }
  return address;
}

export function getLocationAddress(store) {
  if (!store.addresses || !store.addresses.length) {
    return null;
  }

  const address = findAddress(store);
  return {
    ...address,
    toString() {
      return `${this.street1} ${this.city}, ${this.state} ${this.postal}`;
    },
  };
}

export function getLocationCity(store) {
  if (!store.addresses || !store.addresses.length) {
    return null;
  }

  const address = findAddress(store);
  return `${address.city}`;
}

export function getLocationStateWithZip(store) {
  if (!store.addresses || !store.addresses.length) {
    return null;
  }

  const address = findAddress(store);
  return `${address.state} ${address.postal}`;
}

export function getLocationAddressAbreviated(store) {
  if (!store.addresses || !store.addresses.length) {
    return null;
  }

  const address = findAddress(store);
  return {
    ...address,
    toString() {
      return `${this.street1}`;
    },
  };
}

/**
 * Method to get the address for geolocation api
 * @param {Object} address
 * @returns string address
 */
export function getOneLineAddress(address) {
  const addrComponent = [];
  if (address.street1) {
    addrComponent.push(address.street1);
  }
  if (address.street2) {
    addrComponent.push(address.street2);
  }
  if (address.city) {
    addrComponent.push(address.city);
  }
  if (address.state) {
    addrComponent.push(address.state);
  }
  if (address.postal) {
    addrComponent.push(address.postal);
  }
  if (address.country) {
    addrComponent.push(address.country);
  }
  return addrComponent.join(' ');
}

/**
 * Method to sort array of objects
 * @param {Array} arr array of objects to sort
 * @param {*} prop Properties to sort (eg. 'name', 'name.firstName'-incase of nested objects)
 * @param {*} order asc or desc
 * @returns sorted array of objects
 */
export function dynamicSort(arr, prop, order = 'asc') {
  // Base case - if arr is null or undefined or not an array, return
  if (!arr || !Array.isArray(arr)) {
    return arr;
  }
  const properties = prop.split('.');
  const propLength = properties.length;
  const inputArrayClone = JSON.parse(JSON.stringify(arr));
  inputArrayClone.sort(function(a, b) {
    let propA = a;
    let propB = b;
    for (let i = 0; i < propLength; i += 1) {
      propA = propA[properties[i]];
      propB = propB[properties[i]];
    }
    let comparison = 0;
    propA = propA?.toLowerCase();
    propB = propB?.toLowerCase();
    if (propA < propB || !propA) {
      comparison = -1;
    }
    if (propA > propB || !propB) {
      comparison = 1;
    }
    if (!propA) {
      comparison = 1;
    }
    if (!propB) {
      comparison = -1;
    }
    if (!propA && !propB) {
      comparison = 0;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  });
  return inputArrayClone;
}
