<template>
  <div>
    <PlatformRenderer>
      <!-- START : Mobile Edit Button -->
      <template v-slot:onMobile>
        <button
          class="btn btn-link link-primary underline-link-from-center"
          @click="editModifierItem(lineItem.variation)"
          ref="editItemBtnRef"
        >
          <font-awesome-icon icon="pencil" size="sm" /> {{ $t('editItem') }}
        </button>
      </template>
      <!-- END : Mobile Edit Button -->
      <!-- START : Desktop Edit Button -->
      <template v-slot:onDesktop>
        <button
          class="btn btn-link link-primary underline-link-from-center"
          @click="editItem(lineItem.variation)"
          ref="editItemBtnRef"
        >
          <font-awesome-icon icon="pencil" size="sm" /> {{ $t('editItem') }}
        </button>
      </template>
      <!-- END : Desktop Edit Button -->
    </PlatformRenderer>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ModalService from '@/modules/modals/services/modal.service';
import WCItemModifierRoot from '@/modules/itemModifiers/components/WCItemModifierRoot/WCItemModifierRoot.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';

export default {
  name: 'WCEditItemButton',
  props: {
    lineItem: {},
  },
  components: { PlatformRenderer },
  methods: {
    ...mapActions({
      modifyItem: 'items/modifyItem',
    }),
    async editItem(variation) {
      try {
        await ModalService.open(WCItemModifierRoot, {
          lineItem: this.lineItem,
          item: this.lineItem.item,
          editingItem: true,
          variation,
          cartInstructions: this.lineItem.instructions,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.editItemBtnRef) this.$refs.editItemBtnRef.focus();
      }
    },
    /**
     * Method to Edit Modifier Item for Mobile
     */
    editModifierItem(variation) {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.lineItem.item.id, name: this.lineItem.item.name },
        query: { variation, editingItem: true },
      });
    },
  },
};
</script>
