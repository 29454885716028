import Locales from '../../../locales';
/**
 * mixin for language switching i18n
 */
export default {
  data() {
    return {
      Locales,
      activeLocales: [],
    };
  },
  async created() {
    await this.getLocales;
  },
  computed: {
    getLocales() {
      if (this.$configuration.i18nLanguage && this.$configuration.i18nLanguage.length) {
        return Locales.forEach(Locale => {
          const languages = [];
          if (this.$configuration.i18nLanguage.includes(Locale.code)) {
            languages.push(Locale);
            this.activeLocales.push(Locale);
            return languages;
          }
          return languages;
        });
      }
      this.activeLocales = Locales;
      return Locales;
    },
  },
  methods: {
    switchLocale(locale) {
      this.$i18n.locale = locale.code;
      localStorage.setItem('locale', locale.code);
    },
  },
};
