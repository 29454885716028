import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
    }),
    showEbtForCustomer(): boolean {
      return (
        (this.isCustomer || this.isEmployee) &&
        this.isEbtConfigured &&
        (this.isCustomerEbtWhitelisted || !this.isEbtWhitelisted)
      );
    },
    isEbtConfigured(): boolean {
      return (
        this.$configuration?.thirdPartyServiceProfiles?.filter(
          profile => profile.thirdPartyServiceProvider?.type === 2,
        )?.length > 0
      );
    },
    isEbtWhitelisted(): boolean {
      return this.$configuration?.whitelistEbt;
    },
    isCustomerEbtWhitelisted(): boolean {
      return this.isCustomerOnFeatureWhitelist('ebt');
    },
  },
  methods: {
    isCustomerOnFeatureWhitelist(feature): boolean {
      if (!this.user || !this.user.pf) return false;
      const cleanedPowerField = this.user?.pf?.toLowerCase()?.replace(/\s/g, '');

      return cleanedPowerField?.split(',')?.some(ele => {
        return ele === feature;
      });
    },
  },
};
