import {
  faMapMarkerExclamation,
  faBadgeCheck,
  faWandMagicSparkles,
  faArrowUpFromSquare,
  faArrowRightLong,
  faBarcodeRead,
  faExclamationCircle,
  faCartXmark,
  faLaptopMobile,
  faDash,
  faAngleUp,
  faAngleDown,
  faExclamationTriangle,
  faPause,
  faPlay,
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';

export default [
  faMapMarkerExclamation,
  faBadgeCheck,
  faWandMagicSparkles,
  faArrowUpFromSquare,
  faArrowRightLong,
  faBarcodeRead,
  faExclamationCircle,
  faCartXmark,
  faLaptopMobile,
  faDash,
  faAngleUp,
  faAngleDown,
  faExclamationTriangle,
  faPause,
  faPlay,
  faCircleInfo,
];
