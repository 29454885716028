<template>
  <div
    class="d-flex flex-column"
    :class="{ 'mobile-padding': isMobile }"
    role="group"
    aria-labelledby="modifier-quantity-tab"
  >
    <h4 class="pb-2">{{ quantityLabel }}</h4>
    <WCSimpleReactiveQuantityAdjustor
      class="border border-primary w-51 mb-3"
      v-model="modifierQuantity"
      :displayValue="displayModifierQuantity"
      :initialValue="minimumModifierQuantity"
      :hasRemove="false"
      :incrementStep="modifierQuantityIncrement"
      :maxQty="noMax"
      :minQty="minimumModifierQuantity"
      :isNextIncrementValid="isNextModifierQuantityIncrementValid"
      :isNextDecrementValid="isNextModifierQuantityDecrementValid"
    />
    <template v-if="isSoldByRandomWeight(item)">
      <h4 class="pb-2">{{ $t('howManyWouldYouLike') }}</h4>
      <WCSimpleReactiveQuantityAdjustor
        class="border border-primary w-51"
        v-model="modifierWeightedQuantity"
        :displayValue="displayModifierWeightedQuantity"
        :initialValue="minimumModifierWeightedQuantity"
        :hasRemove="false"
        :incrementStep="modifierWeightedQuantityIncrement"
        :maxQty="noMax"
        :minQty="minimumModifierWeightedQuantity"
        :isNextIncrementValid="isNextModifierWeightedQuantityIncrementValid"
        :isNextDecrementValid="isNextModifierWeightedQuantityDecrementValid"
      />
    </template>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import { PlatformMixin } from '@/modules/platform';
import WCSimpleReactiveQuantityAdjustor from '@/components/WCSimpleReactiveQuantityAdjuster/WCSimpleReactiveQuantityAdjuster.vue';

export default {
  components: { WCSimpleReactiveQuantityAdjustor },
  mixins: [ItemModifiersMixin, CartControls, ItemModifierQuantityMixin, PlatformMixin],
  props: {
    item: {
      required: true,
    },
    lineItem: {
      required: false,
    },
  },
  computed: {
    noMax() {
      return new BigNumber('Infinity');
    },
    quantityLabel() {
      if (this.isSoldByRandomWeight(this.item)) {
        return this.$t('selectYourWeight');
      }
      return this.$t('howManyWouldYouLike');
    },
    displayModifierQuantity() {
      return this.getDisplayQuantity(this.item, this.modifierQuantity);
    },
    minimumModifierQuantity() {
      return this.getMinimumQuantity(this.item);
    },
    modifierQuantityIncrement() {
      return this.getQuantityIncrement(this.item);
    },
    isNextModifierQuantityIncrementValid() {
      return this.isNextQuantityIncrementValid(
        this.item,
        this.modifierQuantity,
        this.modifierWeightedQuantity,
      );
    },
    isNextModifierQuantityDecrementValid() {
      return this.isNextQuantityDecrementValid(
        this.item,
        this.modifierQuantity,
        this.modifierWeightedQuantity,
      );
    },
    displayModifierWeightedQuantity() {
      if (!this.isSoldByRandomWeight(this.item)) {
        return '';
      }
      return this.getDisplayWeightedQuantity(this.item, this.modifierWeightedQuantity);
    },
    minimumModifierWeightedQuantity() {
      return this.getMinimumWeightedQuantity(this.item);
    },
    modifierWeightedQuantityIncrement() {
      if (!this.isSoldByRandomWeight(this.item)) {
        return new BigNumber('NaN');
      }
      return this.getWeightedQuantityIncrement(this.item);
    },
    isNextModifierWeightedQuantityIncrementValid() {
      if (!this.isSoldByRandomWeight(this.item)) {
        return false;
      }
      return this.isNextWeightedQuantityIncrementValid(
        this.item,
        this.modifierQuantity,
        this.modifierWeightedQuantity,
      );
    },
    isNextModifierWeightedQuantityDecrementValid() {
      if (!this.isSoldByRandomWeight(this.item)) {
        return false;
      }
      return this.isNextWeightedQuantityDecrementValid(
        this.item,
        this.modifierQuantity,
        this.modifierWeightedQuantity,
      );
    },
  },
  created() {
    // If the item is in the cart, initialize with the existing quantities
    if (this.lineItem) {
      this.modifierQuantity = new BigNumber(this.lineItem.quantity);
      this.modifierWeightedQuantity = new BigNumber(this.lineItem.weightedItemQuantity);
    } else {
      this.modifierQuantity = this.minimumModifierQuantity;
      this.modifierWeightedQuantity = this.minimumModifierWeightedQuantity;
    }
  },
};
</script>

<style scoped>
.mobile-padding {
  padding-left: 0.75rem;
}
.w-51 {
  width: 51%;
}
</style>
