import axios from 'axios';

const state = {
  availableCoupons: [],
  clippedCoupons: [],
  redeemedCoupons: [],
  loadingStatus: false,
};

const getters = {
  getAvailableCoupons(state) {
    return state.availableCoupons;
  },
  getClippedCoupons(state) {
    return state.clippedCoupons;
  },
  getRedeemedCoupons(state) {
    return state.redeemedCoupons;
  },
  isLoading(state) {
    return state.loadingStatus;
  },
};

export const CLIP_COUPON = 'CLIP_COUPON';
export const SET_AVAILABLE_COUPONS = 'SET_AVAILABLE_COUPONS';
export const SET_CLIPPED_COUPONS = 'SET_CLIPPED_COUPONS';
export const SET_REDEEMED_COUPONS = 'SET_REDEEMED_COUPONS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

const mutations = {
  [SET_AVAILABLE_COUPONS](state, coupons) {
    state.availableCoupons = coupons;
  },
  [SET_CLIPPED_COUPONS](state, coupons) {
    state.clippedCoupons = coupons;
  },
  [SET_REDEEMED_COUPONS](state, coupons) {
    state.redeemedCoupons = coupons;
  },
  [SET_LOADING_STATUS](state, status) {
    state.loadingStatus = status;
  },
};

const actions = {
  clearUserCoupons({ commit }) {
    commit(SET_LOADING_STATUS, true);
    commit(SET_AVAILABLE_COUPONS, []);
    commit(SET_CLIPPED_COUPONS, []);
    commit(SET_REDEEMED_COUPONS, []);
    commit(SET_LOADING_STATUS, false);
  },
  async setAvailableCoupons({ commit, getters }, availableCouponList) {
    const available = getters.getAvailableCoupons;
    commit(SET_CLIPPED_COUPONS, [...new Set([...available, ...availableCouponList])]);
  },
  async setClippedCoupons({ commit, getters }, clippedCouponList) {
    const clipped = getters.getClippedCoupons;
    commit(SET_CLIPPED_COUPONS, [...new Set([...clipped, ...clippedCouponList])]);
  },
  async setRedeemedCoupons({ commit, getters }, redeemedCouponList) {
    const redeemed = getters.getAvailableCoupons;
    commit(SET_REDEEMED_COUPONS, [...new Set([...redeemed, ...redeemedCouponList])]);
  },
  async fetchMyCoupons({ commit }) {
    try {
      const response = await axios.get('/api/me/coupons');

      const data = response.data;
      const availableCoupons = data.available;
      commit(SET_AVAILABLE_COUPONS, availableCoupons);
      const clippedCoupons = data.clipped;
      commit(SET_CLIPPED_COUPONS, clippedCoupons);
      const redeemedCoupons = data.redeemed;
      commit(SET_REDEEMED_COUPONS, redeemedCoupons);
    } catch (error) {
      console.log(error);
    }
  },
  async clip({ commit, getters }, couponId) {
    try {
      commit(SET_LOADING_STATUS, true);

      const clipped = getters.getClippedCoupons;
      const match = clipped.indexOf(couponId) >= 0;
      if (match) {
        return;
      }

      const response = await axios.post(`api/coupon/clip`, {
        coupons: [couponId],
      });

      commit(SET_CLIPPED_COUPONS, response.data.clipped);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      commit(SET_LOADING_STATUS, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
