const state = {
  showScanner: true,
};

const getters = {
  isScannerVisible() {
    return state.showScanner;
  },
};

export const SET_SHOW_SCANNER = 'SET_SHOW_SCANNER';

const mutations = {
  [SET_SHOW_SCANNER](state, showScanner) {
    state.showScanner = showScanner;
  },
};

const actions = {
  setScannerVisibility({ commit }, visibility) {
    commit(SET_SHOW_SCANNER, visibility);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
