<template>
  <div>
    <nav class="wc-mobile-nav">
      <!-- START: Shop button navigating to home page -->
      <router-link
        class="wc-mobile-nav__link "
        v-bind:class="{ active: active === 'shop' }"
        :to="!isCustomerModeScan ? '/' : '/scan'"
      >
        <font-awesome-icon
          class="wc-mobile-nav__icon"
          :icon="isCustomerModeScan ? 'barcode-read' : 'store'"
        />
        <span class="wc-mobile-nav__text">{{ isCustomerModeScan ? $t('scan') : $t('shop') }}</span>
      </router-link>
      <!-- END: Shop button navigating to home page -->

      <!-- START: Lists button navigating to shopping lists page -->
      <router-link
        class="wc-mobile-nav__link"
        v-bind:class="{ active: active === 'lists' }"
        to="/me/lists"
        v-if="$configuration.listEnabled"
      >
        <font-awesome-icon class="wc-mobile-nav__icon" icon="list-check" />
        <span class="wc-mobile-nav__text">{{ $t('lists') }}</span>
      </router-link>
      <!-- END: Lists button navigating to shopping lists page -->

      <!-- START: AccountDashboard button navigating to my accounts page -->
      <router-link
        class="wc-mobile-nav__link"
        v-bind:class="{ active: active === 'account' }"
        :to="isCustomer ? '/me/edit' : '/login'"
      >
        <font-awesome-icon class="wc-mobile-nav__icon" icon="user" />
        <span class="wc-mobile-nav__text">{{ $t('account') }}</span>
      </router-link>
      <!-- END: AccountDashboard button navigating to my accounts page -->

      <!-- START: Scan button navigating to ACO mode -->
      <div
        v-if="!isCustomerModeScan && isAcceleratedCheckOutEnabled"
        class="wc-mobile-nav__link scan-icon"
        v-bind:class="{ active: active === 'scan' }"
        @click="openScanConfirmModal()"
      >
        <font-awesome-icon class="wc-mobile-nav__icon" icon="barcode-read" />
        <span class="wc-mobile-nav__text">{{ $t('scan') }}</span>
      </div>
      <!-- END: Scan button navigating to ACO mode -->

      <!-- START: Cart button navigating to mobile cart page -->
      <router-link
        class="wc-mobile-nav__link cart"
        v-bind:class="{ active: active === 'mcart' }"
        to="/mcart"
        v-if="($configuration.cartEnabled && isCustomerModeScan) || !isAcceleratedCheckOutEnabled"
      >
        <WCShoppingCartButton
          class="border-0 font-size-2xl wc-mobile-nav-cart-icon unstyled-btn"
          @showItems="goToMyCart"
          :loading="false"
          cartBtnVariant="plain"
          :cartIconColor="active === 'mcart' ? 'var(--primary)' : '#666666'"
        />
        <span class="wc-mobile-nav__text">{{ $t('cart') }}</span>
      </router-link>
      <!-- END: Cart button navigating to mobile cart page -->
    </nav>
    <div class="wc-mobile-nav-filler" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalService from '@/modules/modals/services/modal.service';
import WCScanConfirmModal from '@/views/ScanProduct/modals/WCScanConfirmModal.vue';
import WCShoppingCartButton from '../WCShoppingCartButton/WCShoppingCartButton.vue';

export default {
  components: { WCShoppingCartButton },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isSyncing: 'cart/isSyncing',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    active() {
      const path = this.$route.path;
      if (path === '/me/lists') {
        return 'lists';
      }
      if (path === '/me/rewards') {
        return 'rewards';
      }
      if (path.includes('/me') || path === '/login') {
        return 'account';
      }
      if (path === '/mcart') {
        return 'mcart';
      }
      if (path === '/scan') {
        return 'scan';
      }
      return 'shop';
    },
  },
  data() {
    return {
      isAcceleratedCheckOutEnabled: this.$configuration.scanAndGoEnabled,
    };
  },
  methods: {
    // if in mobile view, clicking on cart icon navigate to mobile cart page /mcart
    goToMyCart() {
      if (this.$route.name !== 'Mobile Cart') this.$router.push('/mcart');
    },
    async openScanConfirmModal() {
      try {
        await ModalService.open(WCScanConfirmModal);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-mobile-nav {
  position: fixed;
  bottom: env(safe-area-inset-bottom, 0);
  width: 100%;
  height: 3.5rem;
  z-index: 2001;
  box-shadow: 0 -0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  display: flex;
  overflow-x: auto;
  border-radius: 10px 10px 0 0;
}

.wc-mobile-nav-filler {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: 2000;
  background-color: var(--white);
  display: flex;
  overflow-x: auto;
}

.wc-mobile-nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: rem(50px);
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: $font-size-sm;
  color: #666666;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.wc-mobile-nav__link:hover {
  background-color: #eeeeee;
}

.active {
  color: var(--primary, $primary);
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 60%;
    border-top: 2px solid var(--primary, $primary);
    position: relative;
    top: rem(-5px);
  }
}
@include media-breakpoint-up(md) {
  .cart {
    &.active {
      &:before {
        top: rem(-4px);
      }
    }
  }
}

.wc-mobile-nav__icon {
  font-size: $font-size-xl;
}

.wc-mobile-nav__text {
  padding-top: $pad-1;
  font-weight: 600;
  font-size: $font-size-sm;
}
</style>
