<template>
  <div class="toast show">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WCToast',
};
</script>
