<template>
  <div class="text-break">
    <span v-if="lineItem && lineItem.linkedItems" class="wc-linked__item font-size-xs">{{
      linkedItemsString
    }}</span>
    <span
      v-if="listItem && listItem.variation && listItem.variation.modifiers"
      class="wc-list__item font-size-xs"
      >{{ listItemsString }}</span
    >
  </div>
</template>

<script>
import { currency } from '@/utils/formatters';

export default {
  props: {
    lineItem: {
      type: Object,
    },
    listItem: { type: Object },
  },
  computed: {
    linkedItemsString() {
      let liText = '';
      if (this.lineItem && this.lineItem.linkedItems) {
        this.lineItem.linkedItems.forEach((item, ind) => {
          if (item.quantity === 0) {
            liText += `NO ${item.detail}`;
          } else if (item.quantity > 1) {
            liText += `${item.detail} x${item.quantity}`;
          } else {
            liText += `${item.detail}`;
          }

          if (item.amount > 0) {
            liText += ` (+${currency(item.amount)})`;
          }

          // Append comma unless last in list
          if (ind !== this.lineItem.linkedItems.length - 1) {
            liText += ', ';
          }
        });
      }
      return liText;
    },

    listItemsString() {
      let liText = '';
      if (this.listItem && this.listItem.variation) {
        this.listItem.variation.modifiers.forEach((item, ind) => {
          if (item.quantity === 0) {
            liText += `NO ${item.name ? item.name : ''}`;
          } else if (item.quantity > 1) {
            liText += `${item.name ? item.name : ''} x${item.quantity}`;
          } else {
            liText += `${item.name ? item.name : ''}`;
          }

          if (item.priceOfModifier > 0) {
            liText += ` (+${currency(item.priceOfModifier)})`;
          }

          // Append comma unless last in list
          if (ind !== this.listItem.variation.modifiers.length - 1) {
            liText += ', ';
          }
        });
      }
      return liText;
    },
  },
};
</script>
