import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject past items access if they're not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function pastItemsAccess(to, from, next) {
  const allowPastItems = store.getters.getConfiguration.pastItemsEnabled;
  const isCustomer = store.getters['user/isCustomer'];

  /* If past items aren't enabled, redirect to not found page
   */
  if (!isCustomer && allowPastItems) {
    requireCustomerLogin(to, from, next);
  } else if (!allowPastItems) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
