<template>
  <WCToast class="wc-simple-toast float-right w-100" :class="`wc-simple-toast--${color}`">
    <WCToastHeader>
      <font-awesome-icon class="mr-2" :class="`text-${color}`" :icon="icon" />
      <strong class="wc-simple-toast__title mr-auto">
        <slot name="title">{{ title }}</slot>
      </strong>
      <button type="button" class="ml-2 mb-1 close" @click="dismiss()" :aria-label="$t('close')">
        <!-- eslint-disable-next-line -->
        <span aria-hidden="true">&times;</span>
      </button>
    </WCToastHeader>
    <WCToastBody class="wc-simple-toast__body">
      <slot v-bind:close="close" v-bind:dismiss="dismiss" v-bind:startTimeout="startTimeout">{{
        message
      }}</slot>
    </WCToastBody>
  </WCToast>
</template>

<script>
import ToastMixin from '../../mixins/ToastMixin';
import WCToast from '../WCToast/WCToast.vue';
import WCToastHeader from '../WCToastHeader/WCToastHeader.vue';
import WCToastBody from '../WCToastBody/WCToastBody.vue';
import SimpleToastMixin from '../../mixins/SimpleToastMixin';

export default {
  name: 'WCSimpleToast',
  mixins: [ToastMixin, SimpleToastMixin],
  components: { WCToast, WCToastHeader, WCToastBody },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-simple-toast {
  border-left-width: 0.25rem;
}

.wc-simple-toast--info {
  border-left-color: var(--info, $info);
}
.wc-simple-toast--success {
  border-left-color: var(--success, $success);
}
.wc-simple-toast--warning {
  border-left-color: var(--warning, $warning);
}
.wc-simple-toast--danger {
  border-left-color: var(--danger, $danger);
}
.wc-simple-toast--primary {
  border-left-color: var(--primary, $primary);
}
</style>
