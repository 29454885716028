<template>
  <div class="toast-header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WCToastHeader',
};
</script>
