<template>
  <div>
    <WCSimpleToast
      class="wc-add-to-list__toast"
      :variant="variant"
      :title="title"
      :close="close"
      :dismiss="dismiss"
    >
      <template v-slot:default>
        <router-link class="link-primary underline-link-from-center" :to="`/me/list/${list.id}`">
          {{ $t('viewYour') }} {{ list.name }} {{ $t('list') }}
        </router-link>
      </template>
    </WCSimpleToast>
  </div>
</template>
<script>
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastMixin from '@/modules/toasts/mixins/ToastMixin';

export default {
  name: 'WCAddToListToast',
  mixins: [ToastMixin],
  components: {
    WCSimpleToast,
  },
  props: {
    list: Object,
    variant: String,
    title: String,
  },
};
</script>
