import { store } from '@/store';
import requireCustomerLogin from '@/router/route-guards/require-customer-login/require-customer-login';

export default async function guardCheckout(to, from, next) {
  const requireCustomerToCheckout = store.getters.getConfiguration.requireCustomerToCheckout;
  const cartEnabled = store.getters.getConfiguration.cartEnabled;
  const isEmployee = store.getters['user/isEmployee'];
  const isCustomer = store.getters['user/isCustomer'];
  const isCustomerModeScan = store.getters['user/isCustomerModeScan'];

  // TODO: Change 'next(...)' to 'return {...}' after Vue Router 4 upgrade
  if (isEmployee && !isCustomer) {
    next({ name: 'EmployeeCustomerSelect' });
  } else if (requireCustomerToCheckout) {
    requireCustomerLogin(to, from, next);
  } else if (!cartEnabled && !isCustomerModeScan) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
