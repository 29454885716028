export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    dismiss: {
      type: Function,
      required: true,
    },
    startTimeout: {
      type: Function,
      required: false,
    },
  },
};
