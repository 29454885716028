/**
 * Function to check if opera version is unsupported
 * Unsupported versions - version 9.x to 12.x
 */
export default function checkIfUnsupportedOpera() {
  const isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0 ||
    navigator.userAgent.indexOf('Opera') !== -1;
  let versionOffset;
  let fullVersion;
  const userAgent = navigator.userAgent;
  // If browser is opera, extract the version number
  if (isOpera) {
    // Older opera version
    versionOffset = userAgent.indexOf('Opera');
    if (versionOffset !== -1) {
      fullVersion = userAgent.substring(versionOffset + 6);
      versionOffset = userAgent.indexOf('Version');
      if (versionOffset !== -1);
      fullVersion = userAgent.substring(versionOffset + 8);
    }
    // Newer opera version
    versionOffset = userAgent.indexOf(' OPR');
    if (versionOffset !== -1) {
      fullVersion = userAgent.substring(versionOffset + 5);
    }
    fullVersion = parseFloat(fullVersion);
    return fullVersion >= 9 && fullVersion < 13;
  }
  return false;
}
