import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCScanModeRemoveCartModal from '@/modules/user/components/WCScanModeRemoveCartModal/WCScanModeRemoveCartModal.vue';
import modalService from '@/modules/modals/services/modal.service';
import logoutUser from '@/utils/services/logoutUser';

export default {
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      isCustomerModeScan: 'user/isCustomerModeScan',
      cartItems: 'cart/getLineItems',
      giftCardItems: 'cart/getGiftCardItems',
      getCartSyncDto: 'cart/getCartSyncDto',
      nonEmptyCarts: 'cart/nonEmptyCarts',
    }),
    isItemAvailableInCart() {
      return this.cartItems.length > 0 || this.nonEmptyCarts.length;
    },
    isGiftCardAvailableInCart() {
      return this.giftCardItems.length > 0;
    },
  },
  methods: {
    ...mapActions({
      removeAllCartItems: 'cart/removeAllCartItems',
      motRemoveAllCartItems: 'cart/motRemoveAllCartItems',
      reloadCart: 'cart/reload',
      syncCart: 'cart/sync',
      setScannerVisibility: 'scan/setScannerVisibility',
      setInStore: 'cart/setInStore',
      setUserFromCookie: 'user/setUserFromCookie',
    }),
    /**
     * Method to create toast
     * @param {String} variation
     * @param {String} title
     * @param {String} message
     */
    createToast(variation, title, message) {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: variation,
          title,
          message,
        },
        timeout: 7000,
      });
    },

    /**
     * Method to get the user scan and go mode
     */
    async isUserInScanAndGoMode() {
      try {
        await axios.get('/api/scan-and-go');
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Method to enter scan and go mode
     */
    async enterScanAndGoMode() {
      try {
        await this.reloadCart();
        if (this.isItemAvailableInCart) {
          await modalService.open(WCScanModeRemoveCartModal, {});
        } else if (this.isGiftCardAvailableInCart && !this.$configuration.orderTypesEnabled) {
          await this.removeAllCartItems();
          this.createToast('success', this.$t('removeGiftCard'), this.$t('removeGiftCardCart'));
        } else if (this.isGiftCardAvailableInCart && this.$configuration.orderTypesEnabled) {
          await this.motRemoveAllCartItems();
          this.createToast('success', this.$t('removeGiftCard'), this.$t('removeGiftCardCart'));
        }
        await this.reloadCart();
        await this.syncCart();
        await axios.post('/api/scan-and-go', {
          enterScanAndGo: true,
        });
        this.createToast(
          'success',
          this.$t('AcceleratedCheckOutMode'),
          this.$t('enterAcceleratedCheckOutModeSuccess'),
        );
      } catch (e) {
        console.error(e);
        if (e !== 'Dismissed') {
          const response = e.response;
          const message =
            response && response.data && response.data.message
              ? response.data.message
              : this.$t('errorInAcceleratedCheckOutMode');
          this.createToast('danger', this.$t('error'), message);
        }
      }
    },

    /**
     * Method to exit scan and go mode
     */
    async exitScanAndGoMode() {
      try {
        this.setInStore(false);
        this.setScannerVisibility(false);

        await axios.post('api/cart', this.getCartSyncDto);
        await this.setUserFromCookie();

        if (this.isUserInScanAndGoMode) {
          if (this.isItemAvailableInCart) {
            await modalService.open(WCScanModeRemoveCartModal, {
              localeHeader: 'exitAcceleratedCheckOutMode',
              localeBody: 'exitAcceleratedCheckOutModeModalBody',
              hasCancel: true,
            });
          } else if (this.isGiftCardAvailableInCart) {
            await this.removeAllCartItems();
            this.createToast('success', this.$t('removeGiftCard'), this.$t('removeGiftCardCart'));
          }

          await axios.post('/api/scan-and-go', {
            enterScanAndGo: false,
          });
          // if we successfully exit scan and go, send the user home, not in finally block because the user could dismiss the modal and not want to actually exit scan and go
          await this.$router.push({ name: 'Home' }).catch(() => {});
        }
        this.createToast(
          'success',
          this.$t('AcceleratedCheckOutMode'),
          this.$t('exitAcceleratedCheckOutModeSuccess'),
        );
      } catch (e) {
        console.error(e);
        if (e !== 'Dismissed' && e.response.data.message !== 'Not authenticated.') {
          const response = e.response;
          const message =
            response && response.data && response.data.message
              ? response.data.message
              : this.$t('errorInAcceleratedCheckOutMode');
          this.createToast('danger', this.$t('error'), message);
        } else if (e.response && e.response.data.message !== 'Not authenticated.') {
          await this.setUserFromCookie();
          await logoutUser();
        } else if (e === 'Dismissed' && this.isCustomerModeScan) {
          this.setScannerVisibility(true);
        } // If the User closes the remove items from cart modal but has already been removed from scan and go mode
        else if (e === 'Dismissed' && !this.isCustomerModeScan) {
          this.removeAllCartItems();
          await axios.post('/api/scan-and-go', {
            enterScanAndGo: false,
          });
          await this.$router.push({ name: 'Home' }).catch(() => {});
        }
        throw e;
      } finally {
        await axios.post('api/cart', this.getCartSyncDto);
        this.setScannerVisibility(true);
      }
    },
  },
};
