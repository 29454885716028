<template>
  <WCAlert class="alert-danger mx-0 w-100">
    <WCAlertCloseButton @close="close" />
    <div class="wc-alert__body">
      <b>{{ $t('importantCartMessage') }}:</b>
      <ul>
        <li v-for="line in outofstockLineItems" :key="line.item.id">
          <router-link class="name underline-link-from-center" :to="`/i/${line.item.id}`">{{
            line.detail
          }}</router-link>
          {{ $t('isNowOutOfStock') }}
        </li>
      </ul>
    </div>
  </WCAlert>
</template>

<script>
import { mapActions } from 'vuex';
import WCAlert from '@/modules/alerts/components/WCAlert/WCAlert.vue';
import WCAlertCloseButton from '@/modules/alerts/components/WCAlertCloseButton/WCAlertCloseButton.vue';

export default {
  name: 'WCOutOfStockAlert',
  components: { WCAlert, WCAlertCloseButton },
  props: {
    outofstockLineItems: {
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      clearOutOfStockItems: 'cart/clearOutOfStockItems',
    }),
    close() {
      this.clearOutOfStockItems();
    },
  },
};
</script>
