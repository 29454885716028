<template>
  <div class="container-fluid" v-if="itemModifiers.linkedItems" role="group">
    <div class="justify-content-between">
      <div class="d-flex">
        <h4
          class="pb-2 wc-linked-item-group__name"
          v-if="itemModifiers.linkedItems && itemModifiers.linkedItems.name"
        >
          {{ itemModifiers.linkedItems.name }}
        </h4>
      </div>
      <div class="wc-item-modifier-group">
        <WCItemModifierLinkedItem
          v-for="linkedItem in linkedItemsList"
          :key="linkedItem.id"
          :linkedItem="linkedItem"
          :itemModifiers="itemModifiers"
          :prohibitAlterations="itemModifiers.linkedItems.prohibitAlterations"
        />
      </div>
    </div>
  </div>
</template>
<script>
import WCItemModifierLinkedItem from '../WCItemModifierLinkedItem/WCItemModifierLinkedItem.vue';

export default {
  name: 'WCItemModifiersLinkedItemsGroup',
  components: {
    WCItemModifierLinkedItem,
  },
  props: {
    itemModifiers: {},
  },
  computed: {
    linkedItemsList() {
      return Object.values(this.itemModifiers.linkedItems.items);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-modifier-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @include media-breakpoint-between(md, lg) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  }
}
</style>
