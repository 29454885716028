export default {
  methods: {
    addClientWidthToStyles(styleName: string): void {
      const width = this.getClientWidth();
      document.documentElement.style.setProperty(styleName, `${width}px`);
    },
    getClientWidth(): number {
      return document.documentElement.clientWidth || document.body.clientWidth;
    },
    getWidthOfClass(className: string): number {
      const element = document.getElementsByClassName(className)[0];
      const positionInfo = element?.getBoundingClientRect();

      return positionInfo?.width;
    },
  },
};
