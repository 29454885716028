<template>
  <div class="wc-layout wc-layout--mobile-headerless">
    <div class="wc-layout__body container-fluid px-0">
      <router-view />
    </div>
    <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
    <WCMobileNavigation v-else-if="!isEmployee" />
  </div>
</template>

<script>
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';

export default {
  name: 'HeaderlessMobile',
  components: {
    WCMobileNavigation,
    WCEmployeeAssociationBanner,
  },
  mixins: [EmployeeMixin],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-headerless {
  .wc-layout__body {
    padding-bottom: $pad-3 * 3.7;
  }
}
</style>
