import { store } from '@/store';

/**
 * Reject cart access if they're not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function cartAccess(to, from, next) {
  const allowCart = store.getters.getConfiguration.cartEnabled;
  const isEmployee = store.getters['user/isEmployee'];
  const isCustomer = store.getters['user/isCustomer'];
  const isCustomerModeScan = store.getters['user/isCustomerModeScan'];

  /*
   * If employee logs in, make them associate with a customer
   * If cart isn't enabled, redirect to not found page
   */
  if (isEmployee && !isCustomer) {
    next({ name: 'EmployeeCustomerSelect' });
  } else if (!allowCart && !isCustomerModeScan) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
