<template>
  <button
    @click.stop="fetchCouponAndOpenModal(couponLineItem)"
    class="btn btn-link underline-link-from-center link-primary"
    :aria-label="$t('couponApplied')"
  >
    <font-awesome-icon icon="scissors" class="icon" :size="iconSize" />
    <span v-if="!onlyIcon" class="ml-2">{{ $t('couponApplied') }}</span>
  </button>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import CouponMixin from '@/modules/coupons/mixins/CouponMixin';

export default {
  name: 'WCAvaliableCoupons',
  mixins: [CartControlsMixin, CouponMixin],
  props: {
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: null,
    },
  },
  computed: {
    couponLineItem() {
      return this.getCoupon(this.cartLineItems, this.item);
    },
  },
};
</script>

<style scoped></style>
