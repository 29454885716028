<template>
  <button
    @click.stop="remove(item, variation)"
    class="btn btn-link underline-link-from-center wc-remove-from-cart link-primary"
    :aria-label="$t('remove')"
  >
    <font-awesome-icon icon="trash" class="icon" :size="iconSize" />
    <span v-if="!onlyIcon" class="ml-2">{{ $t('remove') }}</span>
  </button>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  name: 'WCCartRemoveButton',
  mixins: [CartControlsMixin],
  props: {
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
