import WCIneligibleCartClearModal from '@/modules/modals/components/WCIneligibleCartClearModal/WCIneligibleCartClearModal.vue';
import WCAddToListModal from '@/modules/lists/components/WCAddToListModal/WCAddToListModal.vue';
import ModalService from '@/modules/modals/services/modal.service';
import { mapGetters, mapActions } from 'vuex';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';

export default {
  mixins: [CartItemControls],
  computed: {
    ...mapGetters({
      getCartOrderType: 'cart/getCartOrderType',
      isGuest: 'user/isGuest',
      isGiftCard: 'cart/isGiftCard',
      isSyncing: 'cart/isSyncing',
      carts: 'cart/carts',
    }),
    canSaveCartToList() {
      return !this.isEmpty && !this.isGuest && !this.isGiftCard;
    },
    cartOrderTypeMismatch() {
      if (this.$configuration.orderTypesEnabled) {
        return !this.getCartOrderType;
      }

      return this.getCartOrderType;
    },
  },
  methods: {
    ...mapActions({
      removeAllCartItems: 'cart/removeAllCartItems',
      loadDetails: 'lists/loadDetails',
      reload: 'cart/reload',
      removeAllCartsItems: 'cart/removeAllCartsItems',
    }),

    async saveToList() {
      try {
        await ModalService.open(WCAddToListModal, {
          items: this.cartLineItems,
          disableToast: true,
        });
      } catch (error) {
        console.error(error);
      }

      await this.clearCart();
    },
    async clearCart() {
      await this.removeAllCartItems();
      await this.reload();
    },
  },
  async mounted() {
    if (this.cartOrderTypeMismatch) {
      if (!this.canSaveCartToList) {
        await this.clearCart();
      } else {
        try {
          const response = await ModalService.open(WCIneligibleCartClearModal, {
            close: this.saveToList,
            dismiss: this.clearCart,
          });

          if (response === MODAL_EVENTS.CANCEL) {
            await this.clearCart();
          } else if (response === MODAL_EVENTS.CONFIRM) {
            await this.saveToList();
          }
        } catch (error) {
          await this.clearCart();
          console.error(error);
        }
      }
    }
  },
  data() {
    return {
      MODAL_EVENTS,
    };
  },
};
