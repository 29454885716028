<template>
  <div>
    <div v-if="!isLoading && !isCartsLoading">
      <!--Start Cart Drawer out of stock alert -->
      <div
        class="container-fluid cart-item-border"
        v-if="getOutOfStockLineItems && getOutOfStockLineItems.length > 0"
      >
        <WCOutOfStockAlert :outofstockLineItems="getOutOfStockLineItems" />
      </div>
      <!--End Cart Drawer out of stock alert -->
      <div class="container-fluid" v-for="lineItem in cart.lineItems" :key="lineItem.id">
        <div>
          <WCCartDrawerItem
            v-if="lineItem.type === 1 && lineItem.quantity > 0"
            class="list-group-item wc-cart-item"
            :line-item="lineItem"
          />
        </div>
        <div>
          <WCCartDrawerSpecialOrderItem
            v-if="lineItem.type === 60 && lineItem.quantity > 0"
            class="list-group-item wc-cart-item"
            :soItem="lineItem"
          />
        </div>
      </div>
      <div>
        <WCCartDrawerGiftCard :cart="cart" />
      </div>
    </div>

    <WCSpinner
      v-else-if="isCartsLoading || isLoading"
      class="mx-auto align-self-center"
      size="xl"
      position="center"
    />
  </div>
</template>

<script>
import WCCartDrawerItem from '@/modules/cart/components/WCCartDrawerItem/WCCartDrawerItem.vue';
import { mapGetters } from 'vuex';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCartDrawerSpecialOrderItem from '@/modules/cart/components/WCCartDrawerSpecialOrderItem/WCCartDrawerSpecialOrderItem.vue';
import WCOutOfStockAlert from '@/modules/cart/components/WCOutOfStockAlert/WCOutOfStockAlert.vue';
import WCCartDrawerGiftCard from '../WCCartDrawerGiftCard/WCCartDrawerGiftCard.vue';

export default {
  components: {
    WCCartDrawerItem,
    WCCartDrawerGiftCard,
    WCSpinner,
    WCCartDrawerSpecialOrderItem,
    WCOutOfStockAlert,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    cart: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'cart/isLoading',
      isCartsLoading: 'cart/cartsLoadingStatus',
      getOutOfStockLineItems: 'cart/getOutOfStockLineItems',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-down(xs) {
  .cart-item-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: $margin-3 0;

    &:last-child {
      border: 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .wc-cart-item {
    padding: 0;
  }
}

.wc-cart-item {
  border: 0;
}
</style>
