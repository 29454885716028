<template>
  <WCSimpleToast :title="title" :variant="variant" :close="closeToast" :dismiss="dismissToast">
    <!-- if this is an iphone on safari, show prompt to install -->
    <div v-if="isIOSDeviceOnSafari">
      <template v-if="showImage">
        <WCImage
          imageSrc="/api/img/favicon"
          :imageAlt="getStoreName"
          imageClass="d-block mx-auto my-3"
          width="60px"
          height="60px"
          :defaultImage="false"
          @imgLoadError="showImage = false"
        />
      </template>
      <div class="mb-3">
        <p>{{ $t('iosToastMsg') }}</p>
        <b
          >{{ $t('iosShareMessagePart1') }}
          <font-awesome-icon icon="arrow-up-from-square" class="mx-1" size="2x" />
          {{ $t('iosShareMessagePart2') }}</b
        >
      </div>
    </div>
    <!-- if this is anything other than an iphone, show regular prompt -->
    <div v-else-if="!isIOSDevice">
      <div class="mb-3">{{ message }}</div>
      <!-- START : Installable Button Section -->
      <template v-if="btnSectionType === btnSectionTypes.INSTALLABLE">
        <div class="wc-toast__body__btn-section justify-content-between">
          <button @click="install" class="btn btn-primary">
            {{ $t('toastInstallBtn') }}
          </button>
          <button @click="closeToast" class="btn btn-outline-primary">
            {{ $t('toastNotNowBtn') }}
          </button>
        </div>
      </template>
      <!-- END : Installable Button Section -->
      <!-- START : Got it Button -->
      <template v-else>
        <div class="wc-toast__body__btn-section justify-content-end">
          <button @click="closeToast" class="btn btn-outline-primary">
            {{ $t('toastOkBtn') }}
          </button>
        </div>
      </template>
      <!-- EN : Got it Button -->
    </div>
    <!-- else show a prompt to install from safari -->
    <div v-else>
      <div class="mb-3">{{ message }}</div>
      <!-- START : Installable Button Section -->
      <template v-if="btnSectionType === btnSectionTypes.INSTALLABLE">
        <div class="wc-toast__body__btn-section justify-content-between">
          <button @click="install" class="btn btn-primary">
            {{ $t('toastInstallBtn') }}
          </button>
          <button @click="closeToast" class="btn btn-outline-primary">
            {{ $t('toastNotNowBtn') }}
          </button>
        </div>
      </template>
      <!-- END : Installable Button Section -->
      <!-- START : Got it Button -->
      <template v-else>
        <div class="wc-toast__body__btn-section justify-content-end">
          <p>{{ $t('installFromSafari') }}</p>
        </div>
      </template>
      <!-- EN : Got it Button -->
    </div>
  </WCSimpleToast>
</template>

<script>
import { setAddToHomeScreenCookie } from '@/utils/pwa-utils';
import WCImage from '@/components/WCImage/WCImage.vue';
import ToastMixin from '@/modules/toasts/mixins/ToastMixin';
import WCSimpleToast from '../WCSimpleToast/WCSimpleToast.vue';
import { BROWSER_TYPE, PWA_CONSTANTS } from '../../../../constants/PwaConstants';

export default {
  name: 'WCPwaPromotionToast',
  mixins: [ToastMixin],
  components: { WCSimpleToast, WCImage },
  props: {
    deferredPrompt: {
      type: Event,
      required: false,
    },
    browserType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      btnSectionType: null,
      title: 'Install App',
      variant: 'primary',
      message: null,
      btnSectionTypes: PWA_CONSTANTS.TOAST_BTN_TYPE,
      isIOSDeviceOnSafari: false,
      isIOSDevice: false,
      showImage: true,
    };
  },
  methods: {
    /**
     * Method to share the app
     */
    async navShare() {
      const shareData = {
        title: this.$configuration.webAppManifest.name,
        url: this.$configuration.webAppManifest.start_url,
      };
      try {
        if (navigator.share) {
          await navigator.share(shareData);
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * Method to dismiss toast which is invoked on timeout
     */
    dismissToast() {
      this.dismissPrompt();
      this.dismiss();
    },

    /**
     * Method to close toast
     */
    closeToast() {
      this.dismissPrompt();
      this.close();
    },

    /**
     * Method to dismiss install prompt
     */
    async dismissPrompt() {
      if (this.deferredPrompt) {
        this.deferredPrompt = null;
      }
      setAddToHomeScreenCookie();
    },

    /**
     * Method to install the pwa app
     */
    async install() {
      await this.deferredPrompt.prompt();
      this.closeToast();
    },

    /**
     * Method to get current toast config
     */
    getCurrentToastConfig() {
      return PWA_CONSTANTS.TOAST_CONFIG_BY_BROWSER_TYPE[this.browserType];
    },

    /**
     * Method to update toast based on browser type
     */
    updateToastByBrowserType() {
      if (this.browserType === BROWSER_TYPE.IOS) {
        this.isIOSDevice = true;
        if (/^((?!chrome|android|CriOS|FxiOS|EdgiOS).)*safari/i.test(navigator.userAgent)) {
          this.isIOSDeviceOnSafari = true;
        }
      } else {
        const currentToastConfig = this.getCurrentToastConfig();
        this.btnSectionType = currentToastConfig.BTN_SECTION_TYPE;
        this.message = this.$t(currentToastConfig.MESSAGE);
      }
    },
  },
  created() {
    this.updateToastByBrowserType();
  },
  computed: {
    getStoreName() {
      return this.$configuration.store.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.share-btn {
  border: 1px solid var(--gray-500, $gray-500);
  -webkit-box-shadow: 0 0 5px var(--gray-500, $gray-500);
  box-shadow: 0 0 5px var(--gray-500, $gray-500);
}
.wc-toast__body__btn-section {
  display: flex;
  flex-wrap: wrap;

  .btn {
    width: 45%;
  }
}
</style>
