<template>
  <button class="btn btn-link link-primary underline-link-from-center" @click="add()">
    <font-awesome-icon class="mr-1" icon="bookmark" />
    {{ $t('saveForLater') }}
  </button>
</template>
<script>
import { mapActions } from 'vuex';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';
import WCAddToListToast from '@/modules/lists/components/WCAddToListToast/WCAddToListToast.vue';

export default {
  name: 'WCSaveForLaterButton',
  mixins: [CartControls, ItemAddToListMixin],
  props: {
    item: { type: Object },
    variation: {},
  },
  computed: {
    itemVariation() {
      return this.variation && this.variation.id ? this.variation.id : this.variation;
    },
  },
  methods: {
    ...mapActions({
      loadDetails: 'lists/loadDetails',
      fetch: 'lists/fetch',
    }),
    async add() {
      try {
        const list = await this.addItemToList({
          list: { name: 'Save for later', type: 'SAVE_FOR_LATER' },
          item: this.item,
          variation: this.itemVariation,
          instructions: this.cartInstructions,
        });
        this.remove(this.item, this.variation);
        this.loadDetails({ list });
        ToastService.open(WCAddToListToast, {
          props: {
            variant: 'favorite',
            title: 'Item added to Save For Later',
            list,
            close: {},
            dismiss: {},
          },
          timeout: 2000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
  },
};
</script>
