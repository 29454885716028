<template>
  <DesktopDefault>
    <div class="wc-account__title border-secondary pb-2">
      <slot name="breadcrumbs">
        <!-- Pass the WCBreadcrumb component here -->
      </slot>

      <h1>
        <slot name="title">
          <!-- Pass the dashboard title text here -->
        </slot>
      </h1>
    </div>
    <div class="d-flex">
      <div class="w-25">
        <slot name="sidebar">
          <!-- Pass the DashboardSidebar component here -->
        </slot>
      </div>
      <div class="wc-layout__body w-75">
        <router-view />
      </div>
    </div>
  </DesktopDefault>
</template>

<script>
import DesktopDefault from '@/layouts/DesktopDefault.vue';

export default {
  name: 'Dashboard',
  components: { DesktopDefault },
};
</script>

<style scoped>
.wc-layout--account {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.wc-account__title {
  border-bottom-style: solid;
  border-bottom-width: 3px;
}
</style>
