<template>
  <div>
    <div class="wc-store-select-row">
      <div class="wc-store-select-width">
        <div v-if="!getLocationAddress && !getLocationPhone && !getLocationEmail && !getStoreName">
          <p :aria-label="$t('noContactInfoAvailable')">{{ $t('noContactInfoAvailable') }}</p>
        </div>
        <div v-else class="mr-2">
          <div>
            <span class="font-weight-bold">{{ getStoreName }}</span>
          </div>
          <!-- START : Store address details -->
          <div v-if="getLocationAddress">
            <div>
              <span v-if="getLocationAddress.street1 || getLocationAddress.street2">
                <span
                  :aria-label="$t('streetAddress1')"
                  class="mr-1"
                  v-if="getLocationAddress.street1"
                  >{{ getLocationAddress.street1 }},</span
                >
                <span
                  :aria-label="$t('streetAddress2')"
                  class="mr-1"
                  v-if="getLocationAddress.street2"
                  >{{ getLocationAddress.street2 }},</span
                >
              </span>
              <span v-if="getLocationAddress.city" :aria-label="$t('city')"
                >{{ getLocationAddress.city }},
              </span>
              <span v-if="getLocationAddress.state" :aria-label="$t('state')"
                >{{ getLocationAddress.state }}
              </span>
              <span v-if="getLocationAddress.postal" :aria-label="$t('zipCodePostal')">{{
                getLocationAddress.postal
              }}</span>
            </div>
          </div>
          <!-- END : Store address details -->

          <!-- START : Store phone details -->
          <div
            v-if="isStoreSelected"
            :class="{ 'wc-store-select-row': !isMobile, 'wc-store-select-row-mobile': isMobile }"
          >
            <div v-if="getLocationPhone">
              <div class="store-select store-select__contact-info mr-2">
                <font-awesome-icon class="mr-2" icon="phone" :title="$t('phoneNumber')" />
                <span :aria-label="$t('phoneNumber')">{{ getLocationPhone.phone }}</span>
              </div>
            </div>
            <!-- END : Store phone details -->

            <!-- START : Store email details -->
            <div v-if="getLocationEmail">
              <div class="store-select store-select__contact-info">
                <font-awesome-icon class="mr-2" icon="envelope" :title="$t('emailAddress')" />
                <span :aria-label="$t('email')">{{ getLocationEmail.email }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wc-store-select-home-width">
        <div>
          <div
            v-if="isHomeStore"
            :aria-label="$t('homeStore')"
            class="font-weight-bold text-primary mr-2"
          >
            <font-awesome-icon :icon="['fa', 'house']" class="mr-1" :title="$t('homeStore')" />
            {{ $t('homeStore') }}
          </div>
          <div
            v-else
            :aria-label="$t('makeHomeStore')"
            class="underline-link-from-center font-weight-bold text-primary mr-2"
            @click="setHomeStore"
            role="button"
          >
            {{ $t('makeHomeStore') }}
          </div>
        </div>
        <div
          v-if="store.distance"
          class="text-right font-size-sm mr-2"
          :aria-label="$t('distanceToStore')"
        >
          {{ distanceInMiles }} {{ $t('milesAbbr') }} / {{ distanceInKm }}
          {{ $t('kilometersAway') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import { mapGetters } from 'vuex';
import { getLocationEmail, getLocationPhone, getLocationAddress } from '@/utils';

export default {
  props: {
    store: {},
    selectedStoreId: null,
    isMobile: {
      type: Boolean,
    },
  },
  mixins: [UserMixin],
  components: {},
  data() {
    return {
      selectedStore: {},
      hasFocus: false,
    };
  },
  methods: {
    async setHomeStore() {
      await this.setUserHomeStore(this.store.id);
    },
  },
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    isHomeStore() {
      return this.getUser?.home === this.store.id;
    },
    getStoreName() {
      return this.store.name;
    },
    getLocationAddress() {
      return getLocationAddress(this.store);
    },
    getLocationPhone() {
      return getLocationPhone(this.store);
    },
    getLocationEmail() {
      return getLocationEmail(this.store);
    },
    isStoreSelected() {
      return this.store.id === this.selectedStoreId;
    },
    distanceInMiles() {
      return (this.store.distance * 0.000621371).toFixed(1);
    },
    distanceInKm() {
      return (this.store.distance * 0.001).toFixed(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-store-select-row {
  display: flex;
  flex-direction: row;
}

.wc-store-select-row-mobile {
  display: flex;
  flex-direction: column;
}

.wc-store-select-width {
  width: 70%;
}

.wc-store-select-home-width {
  width: 30%;
}
</style>
