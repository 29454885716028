<template>
  <div class="wc-item-text">
    <div v-if="item.name">
      <router-link
        class="name link-dark underline-link-from-center"
        :to="`/i/${item.id}`"
        :aria-label="productLabel"
      >
        {{ item.name }}
      </router-link>
    </div>
    <div v-if="item.brand">
      <router-link
        class="underline-link-from-center small text-primary"
        :to="`/b/?g=BRD-${item.brandId}`"
        :aria-label="brandLabel"
      >
        {{ item.brand }}
      </router-link>
    </div>
    <div v-if="item.alternateName">
      <router-link :to="`/i/${item.id}`" class="small underline-link-from-center">
        {{ item.alternateName }}
      </router-link>
    </div>
    <div v-if="item.autoDiscount">
      <a class="small wc-auto-discount underline-link-from-center">
        {{ item.autoDiscount }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WCCartDrawerItemText',
  props: {
    lineItem: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      item: this.lineItem.item,
    };
  },
  computed: {
    productLabel() {
      return `${this.$t('product')} ${this.item.name}`;
    },
    brandLabel() {
      return `${this.$t('brand')} ${this.item.brand}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-text {
  display: flex;
  flex-direction: column;
  min-width: 10em;
}

.wc-auto-discount {
  color: var(--info, $info);
}

.text-full--width {
  max-width: 100%;
}
</style>
