import { store } from '@/store';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleActionableToast from '@/modules/toasts/components/WCSimpleActionableToast/WCSimpleActionableToast.vue';
import logoutUser from '@/utils/services/logoutUser';

export default async function requireLogout(to, from, next) {
  const isNotAuthenticated = store.getters['user/isGuest'];
  const isEmployee = store.getters['user/isEmployee'];
  const isCustomerModeScan = store.getters['user/isCustomerModeScan'];
  /**
   * Check if no user(customer or employee) is logged in
   */
  if (isNotAuthenticated && !isEmployee) {
    // TODO: Change 'next(...)' to 'return {...}' after Vue Router 4 upgrade
    // Check if route has scan true param, if yes then append to subsequent routes
    if (!(to.query?.scan === 'true' || to.query?.scan === 'false') && from.query?.scan === 'true') {
      // eslint-disable-next-line no-param-reassign
      to.query.scan = 'true';
      next({ path: to.path, query: to.query });
    } else {
      next();
    }
  } else {
    try {
      const LOGOUT = 'LOGOUT_ACTION';
      // Already logged in. Show Actionable Toast with signout button
      const value = await ToastService.open(WCSimpleActionableToast, {
        props: {
          variant: 'warning',
          title: 'Authentication',
          message: "You'll need to sign out first",
          btnList: [
            {
              actionName: LOGOUT,
              text: 'Sign Out',
            },
          ],
        },
        timeout: 7000,
      });
      // On click of signout Button, Try to logout the user
      if (value === LOGOUT && logoutUser()) {
        // Successfully logged out the user
        next();
      }
      // On dismissing the toast
      else if (isCustomerModeScan) {
        next({ name: 'Scan Products', query: { route: 'login' } });
      } else {
        next({ name: 'Home' });
      }
    } catch (err) {
      if (isCustomerModeScan) {
        next({ name: 'Scan Products', query: { route: 'login' } });
      } else {
        // On dismissing the toast
        next({ name: 'Home' });
      }
    }
  }
}
