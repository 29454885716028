import { store } from '@/store';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

export default async function requireCustomerLogin(to, from, next) {
  const isAuthenticated = store.getters['user/isCustomer'];
  const isEmployee = store.getters['user/isEmployee'];

  // TODO: Change 'next(...)' to 'return {...}' after Vue Router 4 upgrade
  if (isAuthenticated) {
    next();
  }
  // If employee(not associated as customer) tries to access the account page, return to EmployeeCustomerSelect page
  else if (isEmployee) {
    next({ name: 'EmployeeCustomerSelect' });
    ToastService.open(WCSimpleToast, {
      props: {
        variant: 'warning',
        title: 'Authentication',
        message: 'Associate as customer to access the page',
      },
      timeout: 7000,
    });
  } else {
    // Not logged in
    ToastService.open(WCSimpleToast, {
      props: {
        variant: 'warning',
        title: 'Authentication',
        message: "You'll need to login first",
      },
      timeout: 7000,
    });
    next({ name: 'Login', query: { redirectTo: to.fullPath } });
  }
}
