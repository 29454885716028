import { PropType } from 'vue';
import ItemSummary from '@/models/entities/items/item-summary';
import {
  isAdditionalScanCode,
  isSoldByWeight,
  isSoldByAverageWeight,
  isSoldByRandomWeight,
  hasWeightAbbreviation,
  isPriceDivided,
  unitPrice,
  suggestedPrice,
  actualPrice,
  hasActualPrice,
  averageWeight,
  discountAmount,
  isDiscounted,
  isDiscountedInCart,
} from '@/utils/item-utils';
import { currency } from '@/utils/formatters';

export default {
  props: {
    item: {
      type: Object as PropType<ItemSummary>,
      required: true,
    },
  },
  computed: {
    isAdditionalScanCode() {
      return isAdditionalScanCode(this.item);
    },
    isSoldByWeight() {
      return isSoldByWeight(this.item);
    },
    isSoldByAverageWeight() {
      return isSoldByAverageWeight(this.item);
    },
    isSoldByRandomWeight() {
      return isSoldByRandomWeight(this.item);
    },
    hasWeightAbbreviation() {
      return hasWeightAbbreviation(this.item);
    },
    isPriceDivided() {
      return isPriceDivided(this.item);
    },
    unitPrice() {
      return unitPrice(this.item);
    },
    suggestedPrice() {
      return suggestedPrice(this.item);
    },
    actualPrice() {
      return actualPrice(this.item);
    },
    hasActualPrice() {
      return hasActualPrice(this.item);
    },
    averageWeight() {
      return averageWeight(this.item);
    },
    discountAmount() {
      return discountAmount(this.item);
    },
    isDiscounted() {
      return isDiscounted(this.item);
    },
    isDiscountedInCart() {
      return isDiscountedInCart(this.item);
    },
    unitDisplay(): string {
      if (this.hasWeightAbbreviation) {
        return this.item.weightProfile.abbrv;
      }
      return this.$t('eachAbbr');
    },
    suggestedPriceDisplay(): string {
      return this.$t('xForY', { x: currency(this.suggestedPrice.toNumber()), y: this.unitDisplay });
    },
    actualPriceDisplay(): string {
      const actualPriceFormatted = currency(this.actualPrice.toNumber());
      if (this.isPriceDivided) {
        return this.$t('xForY', { x: this.item.actualPriceDivider, y: actualPriceFormatted });
      }
      return this.$t('xForY', { x: actualPriceFormatted, y: this.unitDisplay });
    },
    averagePriceDisplay(): string {
      if (!this.isSoldByAverageWeight) {
        return '';
      }
      const averagePriceValue = this.unitPrice.multipliedBy(this.averageWeight);
      const averagePrice = currency(averagePriceValue.toNumber());
      return this.$t('approximateEach', { price: averagePrice });
    },
    suggestedAveragePriceDisplay(): string {
      if (!this.isSoldByAverageWeight) {
        return '';
      }
      const suggestedPriceValue = this.suggestedPrice.multipliedBy(this.averageWeight);
      const suggestedPriceDisplay = currency(suggestedPriceValue.toNumber());
      return this.$t('approximateEach', { price: suggestedPriceDisplay });
    },
  },
};
