import mailgo from 'mailgo';

export default function() {
  // Mailgo configurations

  const mailGoAction = {
    gmail: true,
    outlook: true,
    yahoo: true,
    skype: false,
    telegram: false,
    whatsapp: false,
    copy: true,
    default: true,
  };

  const mailgoConfig = {
    tel: false,
    sms: false,
    dark: false,
    actions: mailGoAction,
    showFooter: false,
  };
  mailgo(mailgoConfig);
}
