<template>
  <div>
    <WCSpinner v-if="isLoading" size="xl" position="center" />
    <div v-if="!isLoading">
      <h4 class="wc-save-for-later__header font-size-xl mt-3 px-3">{{ $t('savedForLater') }}</h4>
      <hr class="border-secondary" />

      <div>
        <div
          class="save-for-later-item-wrap cart-item-border"
          v-for="(listItem, i) in saveForLaterList.items"
          :key="i"
        >
          <WCCartDrawerSaveForLaterListItem :listItem="listItem" v-if="!isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCartDrawerSaveForLaterListItem from '../WCCartDrawerSaveForLaterListItem/WCCartDrawerSaveForLaterListItem.vue';

export default {
  components: { WCCartDrawerSaveForLaterListItem, WCSpinner },
  props: {
    saveForLaterList: {},
  },
  computed: {
    ...mapGetters({
      isLoading: 'lists/isLoading',
    }),
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-image-wrapper {
  max-height: rem(100px);
  max-width: rem(100px);
}

.wc-list__image-link {
  display: flex;
  width: rem(100px);
}
.wc-header {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.wc-save-for-later__wrapper {
  overflow-y: auto;
}

@include media-breakpoint-down(xs) {
  .cart-item-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: $margin-3 0;
    &:last-child {
      border: 0;
    }
  }
}
</style>
