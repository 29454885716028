export function getDevicePlatform() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'; // ipads running iOS13 also return Mac
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  } else {
    os = 'Unknown';
  }

  return os;
}

export const BREAKPOINTS = [
  {
    key: 'xs',
    max: 35.98,
    deviceType: 'extra small',
  },
  {
    key: 's',
    min: 36,
    max: 47.98,
    deviceType: 'small',
  },
  {
    key: 'md',
    min: 48,
    max: 61.98,
    deviceType: 'medium',
  },
  {
    key: 'lg',
    min: 62,
    max: 74.98,
    deviceType: 'large',
  },
  {
    key: 'xl',
    min: 75,
    deviceType: 'extra large',
  },
];

/**
 * Method to check if the media query matches
 * @param {object} breakpointInfo
 * returns boolean
 */
function isMediaMatch(breakpointInfo) {
  let mediaQuery = '';
  if (breakpointInfo.min) {
    mediaQuery = `(min-width: ${breakpointInfo.min}em) `;
  }
  if (mediaQuery && breakpointInfo.max) {
    mediaQuery += 'and ';
  }
  if (breakpointInfo.max) {
    mediaQuery += `(max-width: ${breakpointInfo.max}em)`;
  }
  return window.matchMedia(mediaQuery).matches;
}

/**
 * Method to get Device Class
 * returns string - device class
 */
export function getDeviceClass() {
  let deviceClass = 'extra small';
  BREAKPOINTS.forEach(breakpointInfo => {
    if (isMediaMatch(breakpointInfo)) {
      deviceClass = breakpointInfo.deviceType;
    }
  });
  return deviceClass;
}

export function isMobile() {
  return ['extra small', 'small', 'medium'].includes(getDeviceClass());
}

export function isTab() {
  return ['large'].includes(getDeviceClass());
}

export function isDesktop() {
  return !isMobile() && !isTab();
}

export function isMediumDevice() {
  return ['medium'].includes(getDeviceClass());
}
