<template>
  <WCModal position="center" @dismiss="dismiss('Dismissed')">
    <WCModalHeader class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{ $t('addNoteAboutItem') }}</h3>
      <button @click="dismiss('Dismissed')" class="btn btn-link underline-link-from-center">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <textarea
        class="form-control bg-light border-dark"
        placeholder="Add a note here..."
        :value="instructions"
        @input="instructions = $event.target.value"
      />
    </WCModalBody>
    <WCModalFooter>
      <button @click="save" class="btn btn-primary btn-block">
        {{ $t(saveBtnLabel) }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>

<script>
import { isEmpty } from 'lodash';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';

export default {
  mixins: [ModalMixin],
  props: {
    lineItem: {
      type: Object,
    },
    saveBtnLabel: {
      type: String,
      default: 'save', // translatable string
    },
    initialInstructions: {
      type: String,
      default: null,
    },
  },
  components: {
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCModalFooter,
  },
  data() {
    return {
      instructions: this.lineItem && this.lineItem.instructions ? this.lineItem.instructions : '',
    };
  },
  created() {
    if (!isEmpty(this.initialInstructions)) {
      this.instructions = this.initialInstructions;
    } else if (!isEmpty(this.lineItem?.instructions)) {
      this.instructions = this.lineItem.instructions;
    } else {
      this.instructions = ''; // Start with empty string
    }
  },
  methods: {
    save() {
      this.close({
        lineItem: this.lineItem,
        instructions:
          this.lineItem?.instructions !== this.instructions
            ? this.instructions
            : this.lineItem.instructions,
      });
    },
  },
};
</script>
