export const BROWSER_TYPE = {
  IOS: 'IOS',
  OPERA_MINI: 'OPERA_MINI',
  FIREFOX_ANDROID: 'FIREFOX_ANDROID',
  DEFAULT: 'DEFAULT',
};

// Pwa Promotional Toast Btn Type
const TOAST_BTN_TYPE = {
  INSTALLABLE: 'INSTALLABLE',
  GOT_IT: 'GOT IT',
  NO_BTN: 'NO_BTN',
};
// Pwa Promotion Toast Config
const TOAST_CONFIG_BY_BROWSER_TYPE = {
  [BROWSER_TYPE.IOS]: {
    MESSAGE: 'iosToastMsg',
    BTN_SECTION_TYPE: TOAST_BTN_TYPE.NO_BTN,
  },
  [BROWSER_TYPE.OPERA_MINI]: {
    MESSAGE: 'operaMiniToastMsg',
    BTN_SECTION_TYPE: TOAST_BTN_TYPE.GOT_IT,
  },
  [BROWSER_TYPE.FIREFOX_ANDROID]: {
    MESSAGE: 'firefoxAndroidToastMsg',
    BTN_SECTION_TYPE: TOAST_BTN_TYPE.GOT_IT,
  },
  [BROWSER_TYPE.DEFAULT]: {
    MESSAGE: 'defaultToastMsg',
    BTN_SECTION_TYPE: TOAST_BTN_TYPE.INSTALLABLE,
  },
};
export const PWA_CONSTANTS = {
  EXPIRY_DATE: 30,
  ADD_TO_HOME_COOKIE_NAME: 'add-to-home-screen',
  TOAST_BTN_TYPE,
  // Pwa Promotion Toast Config by Browser Type
  TOAST_CONFIG_BY_BROWSER_TYPE,
};
