/* eslint-disable import/prefer-default-export */
export const SIDE_MENU_EVENTS = {
  OPEN: 'sidemenu:open',
  CLOSE: 'sidemenu:close',
  TOGGLE: 'sidemenu:toggle',
};

// Wallet events
export const WALLET_EVENTS = {
  DELETE_CARD: 'Delete Card',
  CANCEL_DELETE: 'Cancel Delete',
};

// Modal events
export const MODAL_EVENTS = {
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  DISMISS: 'Dismiss',
  HEADER_CANCEL: 'Header Cancel',
};
