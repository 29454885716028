import { getLocationEmail, getLocationPhone, getLocationAddress } from '@/utils';

export default {
  computed: {
    showContactUsPage() {
      return (
        this.locationEmail?.email ||
        this.locationPhone?.phone ||
        this.locationAddress?.location ||
        this.locationAddress?.webcart
      );
    },
    locationEmail() {
      return getLocationEmail(this.$configuration.store);
    },
    locationPhone() {
      return getLocationPhone(this.$configuration.store);
    },
    locationAddress() {
      return getLocationAddress(this.$configuration.store);
    },
  },
};
