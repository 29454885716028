import ToastService from '../modules/toasts/services/ToastService';
import WCPwaPromotionToast from '../modules/toasts/components/WCPwaPromotionToast/WCPwaPromotionToast.vue';
import { BROWSER_TYPE } from '../constants/PwaConstants';
import {
  getBrowserType,
  isAddToHomeScreenCookieUnset,
  isInStandaloneMode,
} from '../utils/pwa-utils';

export default {
  methods: {
    /**
     * Method to show install prompt
     */
    showInstallPrompt() {
      // Check if it is not pwa app && cookie is not set
      if (!isInStandaloneMode() && isAddToHomeScreenCookieUnset()) {
        const browserType = getBrowserType();
        // Check if it is ios, firefox & opera
        if (
          browserType === BROWSER_TYPE.IOS ||
          browserType === BROWSER_TYPE.FIREFOX_ANDROID ||
          browserType === BROWSER_TYPE.OPERA_MINI
        ) {
          ToastService.open(WCPwaPromotionToast, {
            props: {
              browserType,
            },
            timeout: 60000,
          });
        } else {
          window.addEventListener('beforeinstallprompt', event => {
            event.preventDefault();
            if (isAddToHomeScreenCookieUnset()) {
              ToastService.open(WCPwaPromotionToast, {
                props: {
                  deferredPrompt: event,
                  browserType,
                },
                timeout: 60000,
                skipHash: true,
              });
            }
          });
        }
      }
    },
  },
  created() {
    if (this.$configuration.pwaEnabled && !this.isDesktop) this.showInstallPrompt();
  },
};
