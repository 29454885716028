<!-- Template for collapsible section in sidebar -->
<template>
  <WCCollapseCard
    class="w-100 border-0 rounded-0 side-menu-collapse-card"
    :isCollapsedOnLoad="isCollapsedOnLoad"
    bodyClass="wc-card-body-negate--padding"
  >
    <template v-slot:headerTitle>
      <slot name="menuHeader"></slot>
    </template>
    <template v-slot:cardBody>
      <slot name="menuBody"></slot>
    </template>
  </WCCollapseCard>
</template>

<script>
import WCCollapseCard from '../../../../components/WCCollapseCard/WCCollapseCard.vue';

export default {
  components: { WCCollapseCard },
  props: {
    isCollapsedOnLoad: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
</style>
