import Locales from '../locales';

export function isLocaleSupported(code) {
  return Locales.map(locale => locale.code).includes(code);
}

export function getBrowserLocale() {
  const languages = navigator.languages;
  const locale = languages && languages.length ? languages[0] : navigator.language;
  return locale ? locale.split(/-|_/)[0] : undefined;
}
