<template>
  <div class="not-found d-flex flex-column align-items-center justify-content-center">
    <h1 class="not-found__message text-primary mb-4">{{ $t('oops') }}!</h1>
    <p>
      {{
        isMobile
          ? $t('featureNotAvailableForMobileWarning')
          : $t('featureNotAvailableForDesktopWarning')
      }}
    </p>
    <router-link to="/" class="btn btn-outline-primary">{{ $t('returnHome') }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'FeatureNotAvailable',
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    if (!this.isMobile && this.$route.query.route === 'login')
      this.$router.push({ name: 'Exit Accelerated Check Out' });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.not-found {
  min-height: rem(500px);
}
.not-found__title {
  font-size: $font-size-3xl;
}
.not-found__message {
  font-size: $font-size-7xl;
}
</style>
