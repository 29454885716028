import { store } from '@/store';

function checkRouteGaurdAccess(to, from, next) {
  const { routeGuard } = to.meta;
  if (routeGuard) {
    routeGuard(to, from, next);
  } else {
    next();
  }
}

/**
 * Scan and go route validation
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default function routeGuardAccess(to, from, next) {
  const isScanAndGoMode = store.getters['user/isCustomerModeScan'];

  if (isScanAndGoMode) {
    if (
      to.meta.restrictScanAndGo ||
      (to.meta.isScanAndGoPage && !(to.meta.isScanAndGoPage === true))
    ) {
      next({ name: 'Exit Accelerated Check Out', query: { redirectTo: to.fullPath } });
    } else {
      checkRouteGaurdAccess(to, from, next);
    }
  } else if (to.meta.restrictNonScanAndGo) {
    next({ name: 'NotFound' });
  } else {
    checkRouteGaurdAccess(to, from, next);
  }
}
