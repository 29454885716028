const PATTERN = /(\w+)-(\d+)-(\d+)/;

const PREFIX_STOCK_INVENTORY = 'INV';
const PREFIX_ADDITIONAL_SCAN_CODE = 'ASC';

export default class TypedDefaultPk {
  prefix: string;

  pk: number;

  cpk: number;

  constructor(id: string) {
    const match = PATTERN.exec(id);
    if (!match) {
      throw new Error(`Invalid ID: ${id}`);
    }
    this.prefix = match[1];
    this.cpk = Number(match[2]);
    this.pk = Number(match[3]);
  }

  isStockInventory(): boolean {
    return this.prefix === PREFIX_STOCK_INVENTORY;
  }

  isAdditionalScanCode(): boolean {
    return this.prefix === PREFIX_ADDITIONAL_SCAN_CODE;
  }
}
