<template>
  <div class="wc-layout wc-layout--mobile-account-subpage">
    <WCMobileSubpageHeader :title="$route.meta.title ? $route.meta.title : $route.name" />
    <div class="wc-layout__body container-fluid" role="main">
      <div class="row">
        <router-view />
      </div>
    </div>
    <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
    <WCMobileNavigation v-else-if="!isEmployee" />
  </div>
</template>

<script>
import WCMobileSubpageHeader from '@/components/WCMobileSubpageHeader/WCMobileSubpageHeader.vue';
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';

export default {
  name: 'MobileSubpageHeader',
  components: { WCMobileSubpageHeader, WCMobileNavigation, WCEmployeeAssociationBanner },
  mixins: [EmployeeMixin],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-account-subpage {
  .wc-layout__body {
    padding-bottom: $mobile-body-padding-bottom;
  }
}
</style>
