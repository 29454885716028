/* eslint-disable class-methods-use-this */
import { getters, state } from '@/store/index';

class RecaptchaService {
  constructor() {
    this.loaded = false;
    this.key = '';
  }

  configure() {
    this.key = getters.getRecaptchaKey(state);
  }

  isEnabled() {
    return !!this.key;
  }

  load() {
    if (!this.loaded) {
      this.loaded = new Promise((resolve, reject) => {
        if (!this.key) {
          reject(new Error('No Recaptcha key'));
        }
        window.grecaptchaInitCallback = () => {
          resolve();
        };

        const s2 = document.createElement('script');
        s2.type = 'text/javascript';
        s2.async = true;
        s2.src = `https://www.google.com/recaptcha/api.js?onload=grecaptchaInitCallback&render=explicit`;
        s2.onerror = () => reject();
        document.body.appendChild(s2);
      });
    }

    return this.loaded;
  }

  render(element, options) {
    return grecaptcha.render(element, {
      sitekey: this.key,
      size: 'invisible',
      callback: options.callback,
      'expired-callback': options['expired-callback'],
      'error-callback': options['error-callback'],
    });
  }

  reset(id) {
    return grecaptcha.reset(id);
  }

  execute(id) {
    return grecaptcha.execute(id);
  }
}

export default new RecaptchaService();

export const RecaptchaScenarios = {
  CONTACT_US: 'CONTACT_US',
};
