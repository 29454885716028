<template>
  <transition name="wc-expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'WCAutoHeightTransition',
  props: {
    isCart: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Dynamically calculate height and perform animation
     */
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = this.isCart ? '250px' : 'auto';

      const height = this.isCart ? '250px' : getComputedStyle(element).height;
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = height;

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/*---- START : Transition effect for collapse card ----*/

/* Transition enter active and leave active */
.wc-expand-enter-active,
.wc-expand-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

/* Transition enter and leave */
.wc-expand-enter,
.wc-expand-leave-to {
  height: 0;
}

/*---- END : Transition effect for collapse card ----*/
</style>
