import { BigNumber } from 'bignumber.js';
import { mapMutations, mapGetters } from 'vuex';
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  mixins: [ItemModifiersMixin, CartControls],
  computed: {
    ...mapGetters({
      getModifierQuantity: 'items/getQuantity',
      getModifierWeightedQuantity: 'items/getWeightedQuantity',
    }),
    modifierQuantity: {
      get() {
        return this.getModifierQuantity;
      },
      set(x) {
        this.setModifierQuantity(x);
      },
    },
    modifierWeightedQuantity: {
      get() {
        return this.getModifierWeightedQuantity;
      },
      set(x) {
        this.setModifierWeightedQuantity(x);
      },
    },
    /**
     * The price multiplier for the base item, derived from quantity.
     * @return {BigNumber} The price multiplier for the base item, derived from quantity.
     */
    basePriceMultiplier() {
      if (this.isSoldByRandomWeight(this.item)) {
        return this.modifierQuantity.multipliedBy(this.modifierWeightedQuantity);
      }
      return this.modifierQuantity;
    },
    /**
     * The price multiplier for all add on items, derived from the quantity of the base item.
     *
     * Always an integer.
     * @return {BigNumber} The price multiplier for all add on items, derived from the quantity of the base item.
     */
    addOnPriceMultiplier() {
      if (this.isSoldByAverageWeight(this.item)) {
        return this.modifierQuantity
          .dividedBy(new BigNumber(this.item.averageWeight))
          .integerValue(BigNumber.ROUND_HALF_UP);
      }
      if (this.isSoldByRandomWeight(this.item)) {
        return this.modifierWeightedQuantity.integerValue(BigNumber.ROUND_HALF_UP);
      }
      return this.modifierQuantity.integerValue(BigNumber.ROUND_HALF_UP);
    },
  },
  methods: {
    ...mapMutations({
      setModifierQuantity: 'items/SET_QUANTITY',
      setModifierWeightedQuantity: 'items/SET_WEIGHTED_QUANTITY',
    }),
    /**
     * Reset quantity and weighted quantity to their minimums.
     */
    resetQuantities() {
      this.modifierQuantity = this.getMinimumQuantity(this.item);
      this.modifierWeightedQuantity = this.getMinimumWeightedQuantity(this.item);
    },
  },
};
