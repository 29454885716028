<!-- Customer association mode banner in header top -->
<template>
  <div
    class="customer-association-banner d-flex align-items-center justify-content-between bg-danger px-2 py-1"
  >
    <span class="text-white mr-2 banner-text">{{
      $t('associationBanner', { name: customerName })
    }}</span>
    <button class="btn end-association-btn text-danger py-1 px-2 px-md-3" @click="unassociate">
      {{ $t('unassociate') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';

export default {
  name: 'WCEmployeeAssociationBanner',
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    /**
     * Associated customer name to be displayed
     */
    customerName() {
      return this.user && this.user.dispShort ? this.user.dispShort : this.user.disp;
    },
  },
  methods: {
    ...mapActions({
      reloadCart: 'cart/reload',
    }),
    /**
     * Method to unassociate customer from employee
     */
    async unassociate() {
      try {
        await axios.post('/api/employee/cs', { id: null });
        this.reloadCart();
        this.$router.push({
          name: 'EmployeeCustomerSelect',
        });
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: this.$t('customerAssociation'),
            message: this.$t('unassociateSuccess'),
          },
          timeout: 7000,
        });
      } catch (error) {
        console.error(error);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: this.$t('error'),
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.end-association-btn {
  background: var(--white, $white);
}
@include media-breakpoint-down(md) {
  .customer-association-banner {
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    .banner-text {
      flex: 1;
    }
  }
}
@include media-breakpoint-up(lg) {
  .customer-association-banner {
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
