import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject shopping list access if they're not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function shoppingListAccess(to, from, next) {
  const allowShoppingList = store.getters.getConfiguration.listEnabled;
  const isCustomer = store.getters['user/isCustomer'];

  /* If shopping lists aren't enabled, redirect to not found page
   */
  if (!isCustomer && allowShoppingList) {
    requireCustomerLogin(to, from, next);
  } else if (!allowShoppingList) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
