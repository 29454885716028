<template>
  <WCModal @dismiss="isLoading ? null : dismiss('Dismissed')">
    <WCModalHeader class="d-flex justify-content-between">
      <h2>{{ $t('checkEbtBalance') }}</h2>
      <button @click="dismiss('Dismissed')" class="btn btn-link underline-link-from-center">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <EbtBalanceCheck
        :paymentMethod="paymentMethod"
        :token="token"
        :submitText="submitText"
        :showViewEbtItems="showViewEbtItems"
        :showSnapBalance="showSnapBalance"
        :showCashBalance="showCashBalance"
        :hideBalanceDisplay="hideBalanceDisplay"
        @done="close('Done')"
        @errorAcknowledgement="dismiss('Dismissed')"
        @addToWallet="close"
        @viewEbtItems="viewEbtItems"
      />
    </WCModalBody>
  </WCModal>
</template>

<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import EbtBalanceCheck from './EbtBalanceCheck.vue';

export default {
  mixins: [ModalMixin],
  components: {
    EbtBalanceCheck,
    WCModal,
    WCModalHeader,
    WCModalBody,
  },
  props: {
    paymentMethod: {
      type: Object,
    },
    token: {
      type: Object,
    },
    submitText: {
      type: String,
      default: 'checkBalance',
    },
    showViewEbtItems: {
      type: Boolean,
      default: true,
    },
    showSnapBalance: {
      type: Boolean,
      default: true,
    },
    showCashBalance: {
      type: Boolean,
      default: true,
    },
    hideBalanceDisplay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewEbtItems() {
      this.$router.push({
        name: 'Browse',
        query: { WebPaymentMethod: this.paymentMethod?.name },
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
</style>
