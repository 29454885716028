export default {
  methods: {
    /**
     * Method to navigate to browse with selected category group
     * @param {Object} group
     */
    select(group) {
      if (group) {
        this.$router.push({
          name: 'Browse',
          query: { g: group },
        });
      } else {
        this.$router.push({
          name: 'Browse',
        });
      }
    },
  },
};
