import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
    }),
    shouldHideItemPrices() {
      return this.$configuration.hidePriceUntilLogin && this.isGuest;
    },
  },
};
