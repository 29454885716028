export default {
  props: {
    title: String,
    message: String,
    customIcon: Array || String,
    variant: {
      type: String,
      validator: value =>
        ['info', 'success', 'warning', 'danger', 'bug', 'favorite', 'primary'].includes(value),
      default: 'info',
    },
  },
  computed: {
    icon() {
      if (this.customIcon) {
        return this.customIcon;
      }
      switch (this.variant) {
        case 'success':
          return 'check';
        case 'warning':
          return 'triangle-exclamation';
        case 'danger':
          return 'circle-exclamation';
        case 'bug':
          return 'bug';
        case 'favorite':
          return 'heart';
        default:
          return 'bell';
      }
    },
    color() {
      switch (this.variant) {
        case 'success':
          return 'success';
        case 'warning':
          return 'warning';
        case 'danger':
          return 'danger';
        case 'bug':
          return 'danger';
        case 'favorite':
          return 'danger';
        case 'primary':
          return 'primary';
        default:
          return 'info';
      }
    },
  },
};
