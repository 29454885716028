<template>
  <div class="wc-sidebar h-100 border-right border-secondary">
    <div class="text-primary pt-4" role="list">
      <slot>
        <!-- Pass DashboardSidebar* items -->
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardSidebar',
};
</script>

<style lang="scss" scoped>
.wc-sidebar {
}
</style>
