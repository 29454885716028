/* eslint-disable no-undef */
import { getters, state } from '../store/index';

class MapService {
  constructor() {
    this.loaded = false;
    this.geocoder = {};
  }

  load() {
    if (!this.loaded) {
      this.loaded = new Promise((resolve, reject) => {
        window.gmapsInitCallback = () => {
          this.geocoder = new google.maps.Geocoder();
          resolve();
        };

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//maps.googleapis.com/maps/api/js?libraries=geometry&callback=gmapsInitCallback';
        const apiKey = getters.getConfiguration(state).googleMapsApiKey;
        if (apiKey) {
          s.src += `&key=${apiKey}`;
        }
        s.onerror = () => reject(new Error('Google Maps API failed to load.'));
        document.body.appendChild(s);
      });
    }

    return this.loaded;
  }

  unload() {
    if (this.loaded) {
      const scripts = document.head.getElementsByTagName('script');

      window.google.maps = undefined;
      const keywords = ['maps.googleapis'];

      for (let i = scripts.length - 1; i >= 0; i -= 1) {
        const scriptSource = scripts[i].getAttribute('src');
        if (scriptSource != null) {
          if (keywords.filter(item => scriptSource.includes(item)).length) {
            scripts[i].remove();
            // scripts[i].parentNode.removeChild(scripts[i]);
          }
        }
      }

      this.loaded = false;
    }
  }

  /**
   * Geocodes an address to return an object with a latitude and longitude. Note: Google maps must be loaded for this to work.
   */
  geocode(address, isStrictAddressCheck) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ address }, results => {
        if (results && results.length > 0) {
          if (isStrictAddressCheck && results[0].geometry.location_type !== 'ROOFTOP') {
            reject(
              new Error(
                'Could not confirm address. Please ensure all fields are valid and try again.',
              ),
            );
          }
          resolve({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          reject(new Error('Location not found.'));
        }
      });
    });
  }

  /**
   * Determines the distance between two locations. Note: Google maps must be loaded for this to work.
   */
  static distance(a, b) {
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(a.latitude, a.longitude),
      new google.maps.LatLng(b.latitude, b.longitude),
    );
  }

  /**
   * Gets the current location
   */
  static geolocate() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        // Browse says it supports geolocation
        navigator.geolocation.getCurrentPosition(
          position =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          () => reject(new Error('Declined geolocation')),
        );
      } else {
        reject(new Error('Geolocation not supported'));
      }
    });
  }
}

export default MapService;
