import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject customer card access if it's not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function customerCardAccess(to, from, next) {
  const customerCardEnabled = store.getters.getConfiguration.customerCardEnabled;
  const isCustomer = store.getters['user/isCustomer'];

  /* If customer card isn't enabled, redirect to not found page
   */
  if (!isCustomer && customerCardEnabled) {
    requireCustomerLogin(to, from, next);
  } else if (!customerCardEnabled) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
