import { mapGetters } from 'vuex';

export default {
  props: {
    orderType: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCartSyncDto',
      getLineItems: 'cart/getLineItems',
      getGiftCardItems: 'cart/getGiftCardItems',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    cartLineItems() {
      if (this.orderType && this.nonEmptyCarts && !this.isCustomerModeScan) {
        const orderTypeCart = this.nonEmptyCarts.find(cart => cart.orderType === this.orderType.id);
        return orderTypeCart?.lineItems ? orderTypeCart.lineItems : [];
      }

      return this.getLineItems;
    },
    isEmpty() {
      return (
        (!this.cart || !this.cart.items || !this.cart.items.length || this.lineItemCount() === 0) &&
        this.specialOrderItemLineItemCount() === 0
      );
    },
  },
  methods: {
    /**
     * The line item for the requested inventory item
     */
    getItemLineItem(item, variation = null) {
      if (!item) {
        return null;
      }

      return this.getLineItemsOfType(1).find(lineItem => {
        return (
          lineItem.item.id === item.id &&
          ((!lineItem.variation && !variation) || lineItem.variation === variation)
        );
      });
    },
    getLineItemsOfType(type) {
      return this.cartLineItems.filter(lineItem => lineItem.type === type);
    },

    lineItemCount(cart) {
      let lineItems;

      if (cart && cart.lineItems) {
        lineItems = cart.lineItems.filter(
          lineItem => lineItem.type === 1 && lineItem.quantity >= 0,
        );
      } else {
        lineItems = this.getLineItemsOfType(1).filter(lineItem => lineItem.quantity >= 0);
      }

      return lineItems.length;
    },

    specialOrderItemLineItemCount(cart) {
      let lineItems;
      if (cart && cart.lineItems) {
        lineItems = cart.lineItems.filter(
          lineItem => lineItem.type === 60 && lineItem.quantity >= 0,
        );
      } else {
        lineItems = this.getLineItemsOfType(60).filter(lineItem => lineItem.quantity >= 0);
      }

      return lineItems.length;
    },
    cartLineItemCount(orderTypeCart) {
      if (orderTypeCart) {
        return orderTypeCart.giftCardItems ||
          orderTypeCart.specialOrderItems ||
          orderTypeCart.lineItems
          ? this.lineItemCount(orderTypeCart) +
              orderTypeCart.giftCardItems.length +
              this.specialOrderItemLineItemCount(orderTypeCart)
          : 0;
      }
      return this.cart &&
        (this.cart.giftCardItems || this.cart.specialOrderItems || this.cart.lineItems)
        ? this.lineItemCount() + this.getGiftCardItems.length + this.specialOrderItemLineItemCount()
        : 0;
    },
  },
};
