<template>
  <div class="wc-cart-drawer-item-wrapper">
    <div class="wc-cart-drawer-item">
      <!-- Image -->
      <router-link
        :to="`/i/${lineItem.item.id}`"
        :aria-label="`${lineItem.item.brand} ${lineItem.item.name} see description in page`"
        class="img-thumbnail__wrapper wc-item-image clearfix"
      >
        <WCImage
          :imageSrc="getProductImage(lineItem.item.image)"
          :imageAlt="productImgAltText(lineItem.item.name, lineItem.item.brand)"
          imageClass="img-thumbnail"
          defaultClass="font-size-6xl"
          :defaultTitle="noProductImageAltText(lineItem.item.name)"
        />
      </router-link>
      <platform-renderer>
        <!-- Start : Cart item view - Desktop -->
        <template v-slot:onDesktop>
          <div class="wc-cart-items-details__desktop row no-gutters w-100">
            <div class="col-5 px-0">
              <WCCartDrawerItemText :line-item="lineItem" />
              <WCProductPricing class="small" :item="lineItem.item" />
              <div v-if="isSoldByRandomWeight(lineItem.item)">
                <span class="small font-weight-bold">
                  {{ getDisplayQuantityInCart(lineItem.item, lineItem.variation) }}
                </span>
              </div>
              <WCLinkedItems :line-item="lineItem" />
            </div>
            <div class="col-4 justify-content-end pr-0">
              <WCCartDrawerQuantityAdjuster :variation="lineItem.variation" :item="lineItem.item" />
            </div>
            <div class="col-3 pr-0">
              <WCCartDrawerItemPricing :line-item="lineItem" />
            </div>
          </div>
        </template>
        <!-- End : Cart item view - Desktop -->

        <!-- Start : Cart item view - Mobile -->
        <template v-slot:onMobile>
          <div class="d-flex flex-column w-100">
            <div class="row no-gutters d-sm-none">
              <WCCartDrawerItemText :line-item="lineItem" class="pr-2 col-9" />
              <WCCartDrawerItemPricing :line-item="lineItem" class="col-3" />
            </div>

            <WCProductPricing class="small" :item="lineItem.item" />
            <div v-if="isSoldByRandomWeight(lineItem.item)">
              <span class="small font-weight-bold">
                {{ getDisplayQuantityInCart(lineItem.item, lineItem.variation) }}
              </span>
            </div>
            <div class="row mt-2 no-gutters align-items-center">
              <div class="col-12 col-sm-8 d-flex justify-content-between pr-0">
                <WCLinkedItems :line-item="lineItem" />
                <WCCartDrawerQuantityAdjuster
                  :variation="lineItem.variation"
                  :item="lineItem.item"
                  :isEmbeddedItem="!!lineItem.embeddedPrice"
                  :showTitle="false"
                />
              </div>
              <div class="d-none d-sm-block col-sm-4 pr-0">
                <WCCartDrawerItemPricing :line-item="lineItem" />
              </div>
            </div>
          </div>
        </template>
        <!-- End : Cart item view - Mobile -->
      </platform-renderer>
    </div>

    <div>
      <WCPaymentOptionBadges :item="lineItem.item" :ebtOnly="true" class="float-right d-flex" />
    </div>
    <div class="d-flex justify-content-end">
      <WCAvaliableCoupons
        v-if="getCoupon(this.cartLineItems, this.lineItem)"
        :item="lineItem"
        :cartLineItems="cartLineItems"
        class="pr-0"
      />
      <WCEditItemButton
        v-if="lineItem.item.hasModifiers || lineItem.item.hasLinkedItems"
        :lineItem="lineItem"
      />
      <WCSaveForLaterButton
        v-if="!isGuest"
        :item="lineItem.item"
        :variation="lineItem.variation"
        :cartInstructions="lineItem.instructions"
      />
      <WCCartRemoveButton :item="lineItem.item" :variation="lineItem.variation" class="pr-0" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCSaveForLaterButton from '@/modules/lists/components/WCSaveForLaterButton/WCSaveForLaterButton.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCImage from '@/components/WCImage/WCImage.vue';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import WCPaymentOptionBadges from '@/components/WCPaymentOptionBadges/WCPaymentOptionBadges.vue';
import ProductImageInfoMixin from '../../../../mixins/ProductImageInfoMixin';
import WCCartDrawerItemPricing from '../WCCartDrawerItemPricing/WCCartDrawerItemPricing.vue';
import WCCartDrawerQuantityAdjuster from '../WCCartDrawerQuantityAdjuster/WCCartDrawerQuantityAdjuster.vue';
import WCCartRemoveButton from '../WCCartRemoveButton/WCCartRemoveButton.vue';
import WCCartDrawerItemText from '../WCCartDrawerItemText/WCCartDrawerItemText.vue';
import WCEditItemButton from '../WCEditItemButton/WCEditItemButton.vue';
import WCLinkedItems from '../WCLinkedItems/WCLinkedItems.vue';
import WCAvaliableCoupons from '../WCAvaliableCouponsButton/WCAvaliableCoupons.vue';
import CouponMixin from '../../../coupons/mixins/CouponMixin';

export default {
  name: 'WCCartDrawerItem',
  components: {
    WCCartDrawerItemPricing,
    WCCartDrawerQuantityAdjuster,
    WCCartRemoveButton,
    WCCartDrawerItemText,
    WCSaveForLaterButton,
    PlatformRenderer,
    WCLinkedItems,
    WCEditItemButton,
    WCImage,
    WCProductPricing,
    WCAvaliableCoupons,
    WCPaymentOptionBadges,
  },
  mixins: [ProductImageInfoMixin, CartControls, CouponMixin],
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
      isGuest: 'user/isGuest',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-cart-drawer-item-wrapper {
  display: flex;
  flex-direction: column;
}

.wc-cart-drawer-item {
  display: flex;
  gap: 2rem;
  padding: 0 0 $pad-2 0;
}

.wc-cart-items-details__desktop {
  display: flex;
  align-items: center;
}

.wc-item-image {
  object-fit: scale-down;
  width: 30%;
  min-width: rem(50px);
  max-width: rem(100px);
}

@include media-breakpoint-down(xs) {
  .wc-cart-drawer-item {
    gap: 0.5rem;
  }
}
</style>
