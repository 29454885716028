import { store } from '@/store';
import requireCustomerLogin from '../require-customer-login/require-customer-login';

/**
 * Reject past orders access if they're not enabled
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function pastOrdersAccess(to, from, next) {
  const allowPastOrders = store.getters.getConfiguration.pastOrdersEnabled;
  const isCustomer = store.getters['user/isCustomer'];

  /* If past orders aren't enabled, redirect to not found page
   */
  if (!isCustomer && allowPastOrders) {
    requireCustomerLogin(to, from, next);
  } else if (!allowPastOrders) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}
