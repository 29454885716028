import Cookies from 'js-cookie';
import { BROWSER_TYPE, PWA_CONSTANTS } from '../constants/PwaConstants';

/**
 * Detects if device is on iOS
 */
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

/**
 * Method to check if app is opened in Opera Mini
 */
export const isOperaMini = () => navigator.userAgent.indexOf('Opera Mini') > -1;

/**
 * Method to check if the browser if firefox
 */
const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

/**
 * Method to check if the device is android
 */
const isAndroid = () => navigator.platform.toLowerCase().indexOf('android') > -1;

/**
 * Method to check if the app is opened in firefox android
 */
export const isFirefoxAndroid = () => isFirefox() && isAndroid();

/**
 * Detects if device is in standalone mode
 */
export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;

/**
 * Method to get browser type for better pwa instructions
 */
export const getBrowserType = () => {
  let browserType = BROWSER_TYPE.DEFAULT;
  if (isIos()) {
    browserType = BROWSER_TYPE.IOS;
  } else if (isOperaMini()) {
    browserType = BROWSER_TYPE.OPERA_MINI;
  } else if (isFirefoxAndroid()) {
    browserType = BROWSER_TYPE.FIREFOX_ANDROID;
  }
  return browserType;
};

/**
 * Method to check if add to home screen cookie is not set
 */
export const isAddToHomeScreenCookieUnset = () =>
  Cookies.get(PWA_CONSTANTS.ADD_TO_HOME_COOKIE_NAME) === undefined;

/**
 * Method to set add to home screen cookie
 */
export const setAddToHomeScreenCookie = () => {
  Cookies.set(PWA_CONSTANTS.ADD_TO_HOME_COOKIE_NAME, null, {
    expires: PWA_CONSTANTS.EXPIRY_DATE,
  });
};
