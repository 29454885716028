<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { PlatformMixin } from '@/modules/platform';

export default {
  name: 'PlatformProvider',
  mixins: [PlatformMixin],
  provide() {
    const platformInfo = {};

    /* Make the 'isMobile' property on platformInfo reactive */
    Object.defineProperty(platformInfo, 'isMobile', {
      enumerable: true,
      get: () => this.isMobile,
    });

    return { platformInfo };
  },
};
</script>

<style scoped></style>
