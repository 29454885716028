import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import modalService from '@/modules/modals/services/modal.service';
import WCCouponDetailModal from '@/modules/coupons/WCCouponDetailModal/WCCouponDetailModal.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToastVue from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

export default {
  props: {
    coupon: {
      type: Object,
    },
  },
  data() {
    return {
      products: [],
      loading: false,
      productsLoading: false,
      error: null,
      isClipped: false,
      isRedeemed: false,
      loadFailedError: null,
      couponData: {},
    };
  },
  created() {
    this.couponData = this.coupon;
  },
  methods: {
    ...mapActions({
      clip: 'coupons/clip',
      fetchMyCoupons: 'coupons/fetchMyCoupons',
      setAvailableCoupons: 'coupons/setAvailableCoupons',
      setClippedCoupons: 'coupons/setClippedCoupons',
      setRedeemedCoupons: 'coupons/setRedeemedCoupons',
    }),
    /**
     * Should be called at a Higher order component ie Coupon Browse, Coupon Detail Page
     * in order to populate isClipped state for coupons
     */
    async fetchCustomerCoupons() {
      if (!this.isGuest && this.isCustomer) {
        this.fetchMyCoupons();
      }
    },
    /**
     * Populate products / items data for a component
     * Fetch eligible product / item information using a BrowseRequest w/ facets
     */
    async fetchEligibleProducts(couponId) {
      this.productsLoading = true;
      try {
        if (couponId) {
          const requestfacets = {
            cp: [couponId],
          };

          const response = await axios.post('api/b', {
            pn: 1,
            ps: 32,
            facets: requestfacets,
          });
          this.products = response?.data.items;
        }
      } catch (error) {
        this.error = error;
        console.error(error);
      } finally {
        this.productsLoading = false;
      }
    },
    allowedToClip() {
      return !this.isGuest && this.isCustomer;
    },
    async openMoreInfoModal(couponFilter) {
      try {
        await this.fetchEligibleProducts(this.couponData.id);
        await this.fetchCustomerCoupons();
        await modalService.open(WCCouponDetailModal, {
          coupon: this.couponData,
          couponTitle: this.couponTitle,
          listOfUPCs: this.listOfUPCs,
          products: this.products,
          couponFilter,
          clip: this.handleClip,
        });
        // Open Modal Success
      } catch (error) {
        if (error !== 'Dismissed')
          ToastService.open(WCSimpleToastVue, {
            props: {
              variant: 'danger',
              title: this.$t('error'),
              message: this.$t('showCouponMoreInfoFailure'),
            },
            timeout: 7000,
          });
      }
    },
    async handleClip() {
      if (!this.allowedToClip()) {
        ToastService.open(WCSimpleToastVue, {
          props: {
            variant: 'danger',
            title: this.$t('logIn'),
            message: this.$t('showCouponLoginPrompt'),
          },
          timeout: 7000,
        });
      } else if (this.allowedToClip() && !this.isClipped) {
        await this.clip(this.couponData.id);
        if (!this.evaluateClipStatus) {
          ToastService.open(WCSimpleToastVue, {
            props: {
              variant: 'danger',
              title: 'Error Clipping Coupon',
              message: 'Coupon already redeemed',
            },
            timeout: 7000,
          });
        } else {
          this.isClipped = true;
        }
      }
    },
    async fetchCouponAndOpenModal(couponLineItem) {
      if (couponLineItem) {
        const couponId = this.parseCouponForId(couponLineItem);
        await this.fetchCouponFromId(couponId);
        await this.openMoreInfoModal();
      } else {
        ToastService.open(WCSimpleToastVue, {
          props: {
            variant: 'danger',
            title: this.$t('error'),
            message: this.$t('showCouponMoreInfoFailure'),
          },
          timeout: 7000,
        });
      }
    },
    async fetchCouponFromId(couponId) {
      try {
        this.loading = true;
        if (couponId) {
          const response = await axios.get(`api/coupon/${couponId}`);
          this.couponData = response.data;
          this.loadFailedError = false;
        }
      } catch (error) {
        this.error = error;
        this.loadFailedError = true;
      } finally {
        this.loading = false;
      }
    },
    parseCouponForId(couponLineItem) {
      return couponLineItem?.couponProviderId;
    },
    getCoupon(cartLineItems, lineItem) {
      let coupon = null;
      if (cartLineItems && lineItem) {
        cartLineItems.forEach(item => {
          if (item?.couponItemScanCodes) {
            item?.couponItemScanCodes.forEach(scanCode => {
              if (scanCode === lineItem.scancode) coupon = item;
            });
          }
        });
      }
      return coupon;
    },
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isGuest: 'user/isGuest',
      availableCoupons: 'coupons/getAvailableCoupons',
      clippedCoupons: 'coupons/getClippedCoupons',
      redeemedCoupons: 'coupons/getRedeemedCoupons',
      getValidCouponAlerts: 'cart/getExternalCouponAlerts',
    }),
    couponTitle() {
      return this.couponData?.name?.includes(':')
        ? this.couponData?.name?.split(':').pop()
        : this.couponData?.name;
    },
    listOfUPCs() {
      return this.couponData.requirementUpcs?.join(', ');
    },
    evaluateClipStatus() {
      return this.clippedCoupons?.filter(i => i?.includes(this.couponData.id)).length > 0;
    },
    evaulateRedeemedStatus() {
      return this.redeemedCoupons?.filter(i => i?.includes(this.couponData.id)).length > 0;
    },
    productList() {
      return this.products;
    },
    hasExternalCouponCodeAlert() {
      return this.getValidCouponAlerts?.length > 0 ? 'valid' : null;
    },
    hasErrors() {
      return (
        this.hasExternalCouponCodeAlert &&
        this.getValidCouponAlerts?.filter(alert => {
          return alert?.values?.reason;
        })?.length > 0
      );
    },
  },
};
