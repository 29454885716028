// Note the values are tied to Google Analytics events
export default {
  Search: 'view_search_results',
  ItemImpression: 'view_item_list',
  ItemViewed: 'view_item',
  ItemAddedToCart: 'add_to_cart',
  ItemRemovedFromCart: 'remove_from_cart',
  ItemAddedToList: 'add_to_wishlist',
  CheckoutStarted: 'begin_checkout',
  CheckoutStageChanged: 'checkout_progress',
  OrderPlaced: 'purchase',
  CustomerRegistration: 'sign_up',
  Refund: 'refund',
  OrderTypeSwitched: 'order_type_switched',
};
